import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom'

// MUI
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import {  } from '@mui/icons-material'

export default function CustomerLogin({customerLogin}){
    const navi = useNavigate()
    const [otpSent, setOtpSent] = useState(false)
    const [resetCall, setResetCall] = useState(false)

    const [mobile, setMobile] = useState('')
    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [otp, setOtp] = useState('')
    const [warning, setWarning] = useState('')
    const [working, setWorking] = useState(false)

    const login = () => {
        setWorking(true); setWarning('')
        var formData = new FormData()
        formData.append('login', mobile)
        formData.append('password', password)
        fetch(customerLogin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false)
            if(data.success){
                navi('/', {replace: true})
            } else {
                setWarning(data.warning)
            }
        })
    }

    const verify = () => {
        setWarning(''); setWorking(true)
        var formData = new FormData();
        formData.append('verify-mobile', mobile)
        fetch(customerLogin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false)
            if(data.success){
                setOtpSent(true)
            } else {
                setWarning(data.warning)
            }
        })
    }

    const reset = () => {
        setWarning(''); setWorking(true)
        var formData = new FormData();
        formData.append('reset-password', mobile)
        formData.append('password', newPassword)
        formData.append('otp', otp)
        fetch(customerLogin, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false)
            if(data.success){
                navi('/', {replace: true})
            } else {
                setWarning(data.warning)
            }
        })
    }

    function cookie(cname){
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
        cookie('true-customer') && navi('/', {replace: true})
    }, [navi])

    return(
        <Grid className="customer-login-bg" container justifyContent='center' alignItems='center' component={Paper} sx={{height: '100vh', backgroundImage: "url('/assets/customer-login-bg.webp')"}} >
            <Grid item xs={12} md={5} sx={{display: {xs: 'none', md: 'block'}}} >
                <img src="assets/welcome-login.png" alt="e-world login" />
            </Grid>
            <Grid item xs={12} md={6} padding={2} >
                <Typography sx={{letterSpacing: 3, fontWeight: 'bold', textAlign: {xs: 'center', md: 'left'}}} variant='h4' mb={3} mt={3} >WELCOME</Typography>

                <Stack spacing={2} >
                    <>
                    <TextField fullWidth variant="standard" label='Mobile number' type='number' value={mobile} onChange={(event) => setMobile(event.target.value)} disabled={otpSent} />
                    { resetCall === false && <TextField type='password' fullWidth variant="standard" label='Password' value={password} onChange={(event) => setPassword(event.target.value)} /> }
                    </>
                    { otpSent === true &&  <>
                        <TextField fullWidth variant="standard" label='New password' type='text' value={newPassword} onChange={(event) => setNewPassword(event.target.value)} />
                        <TextField fullWidth variant="standard" label='OTP' type='number' value={otp} onChange={(event) => setOtp(event.target.value)} />
                    </>}
                    {warning && <Alert severity="warning">{warning}</Alert> }
                </Stack>

                <Stack direction='row' justifyContent='center' spacing={2} mt={2} >
                    { otpSent === false && resetCall === false && <Button variant="contained" color="warning" onClick={() => login()} disabled={working} >{working ? <CircularProgress color="inherit" size={24} /> : 'LOGIN'}</Button> }
                    { resetCall === true && otpSent === false && <Button variant="contained" color="warning" onClick={() => verify()} disabled={working} >{working ? <CircularProgress color="inherit" size={24} /> : 'VERIFY'}</Button> }
                    { otpSent === true && <Button variant="contained" color="error" onClick={() => reset()} disabled={working} >{working ? <CircularProgress color="inherit" size={24} /> : 'RESET'}</Button> }
                </Stack>

                <Typography align="right" mt={3} ><Button color="inherit" onClick={() => navi('/create-customer/0')} >Create account.</Button></Typography>
                <Typography align="right" ><Button color="inherit" onClick={() => setResetCall(true)} >Forgot password?</Button></Typography>
            </Grid>
        </Grid>
    );
}