import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom'

// MUI
import { Box, Container, Divider, Grid, List, ListItem, Stack, Typography } from '@mui/material'

export default function Footer({api}){

    const [v] = useState(3)

    useEffect(() => {
        var formData = new FormData()
        formData.append('app-version', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            parseInt(data.version) !== v && window.location.reload()            
        })
    }, [])
    
    return(
        <Box pt={5} mt={3} sx={{background: '#F5F5F5'}} >
            <Container className="footer">
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" ml={2}>SOCIAL</Typography><Divider/>
                        <List>
                            <ListItem><a target='_blank' rel="noreferrer" href='https://www.facebook.com/profile.php?id=100088742153819&sk=about_details' >Facebook</a></ListItem>
                            <ListItem><a target='_blank' rel="noreferrer" href='https://www.instagram.com/eworldshoping/' >Instagram</a></ListItem>
                            <ListItem><a target='_blank' rel="noreferrer" href='https://wa.me/916203625936' >WhatsApp</a></ListItem>
                            <ListItem><a target='_blank' rel="noreferrer" href='https://twitter.com/EworldShop' >Twitter</a></ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" ml={2}>ABOUT</Typography><Divider/>
                        <List>
                            <ListItem><Link color="inherit" to='/about'>About us</Link></ListItem>
                            <ListItem><Link color="inherit" to='/contact'>Contact us</Link></ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" ml={2}>PRIVACY</Typography><Divider/>
                        <List>
                            <ListItem><Link to='/privacy'>Privacy-Policy</Link></ListItem>
                            <ListItem><Link to='/terms'>Terms & Conditions</Link></ListItem>
                            <ListItem><Link to='/can-ref'>Cancelation & Refund</Link></ListItem>
                            <ListItem><Link to='/shipping'>Shipping & Return policy</Link></ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12} md={3} >
                        <Typography variant="body1" ml={2}>Registered office address</Typography>
                        <Typography variant="body2" mt={2} ml={2}>
                        State Bank of India, NA, R.P. Path, Durga Sthan Chowk, Katihar, Katihar, Bihar, 854105
                        </Typography>
                    </Grid>
                </Grid>
            </Container>

            <Box sx={{background: '#414A4C'}} p={1} mt={4} >
                <Container>
                    <Stack spacing={2} direction={{xs: 'column', md: 'row'}} alignItems='center' justifyContent={{md: 'space-between', xs: 'start'}}  >
                        <Typography fontSize={14} color='white' >&#169; e-WORLD. All rights reversed (v-{v})</Typography>

                        <a href="https://passionfrom.com" target='_blank' rel="noreferrer">
                            <img style={{width: '120px'}} src="https://passionfrom.com/passionfromassets/whitePassionfrom.png" alt="Designed and Developed by PassionFrom" />
                        </a>
                    </Stack>
                </Container>
            </Box>
        </Box>
    );
}