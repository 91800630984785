import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './index.css';

// MUI
import { createTheme, ThemeProvider } from '@mui/material/styles';

// WEBSITE
import Header from './pages/header';
import Landing from './pages'
import About from './pages/about'
import Contact from './pages/contact';
import Privacy from './pages/privacy';
import Terms from './pages/terms';
import CanRef from './pages/c-&-r';
import Shipping from './pages/shipping';
import Description from './pages/description'
import Checkout from './pages/checkout'
import SearchResult from './pages/search-result'

// USER
import CreateCustomer from './login/create'
import CustomerLogin from './login/customer'
import UserHeader from './customer/header'
import UserProfile from './customer/profile/index'
import UserOrders from './customer/orders/index'
import UserChain from './customer/chain/index'
import UserAddress from './customer/profile/address'
import UserVerifyPayment from './customer/verify-payment'

// ADMIN
import AdminLogin from './login/admin'
import HeaderAdmin from './admin/header'
import Sidebar from './admin/sidebar';
import AdminDashboard from './admin/index';
import AdminProduct from './admin/product/product'
import AdminCustomer from './admin/customer/customer'
import AdminActiveOrder from './admin/order/active-order'
import AdminOrder from './admin/order/order';
import AdminRedeem from './admin/customer/redeem'
import AdminProfile from './admin/profile'

// PUBLIC
import Invoice from './public/invoice'

function Eworld(){

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1300,
        xl: 1920
      },
    },

    palette: {
      primary: {
        light: '#00D4B7',
        main: '#00bfa5',
        dark: '#009480',
        contrastText: '#ffffff',
      },
      secondary: {
        light: '#FF095F',
        main: '#f50057',
        dark: '#D20049',
        contrastText: '#ffffff',
      }
    },
    typography: {
      button: {
        textTransform: 'capitalize'
      }
    },

    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none'
          }
        }
      }
    }
  });

  const publicApi = '/eworldassets/api/public/index'

  const product = '/eworldassets/products/'
  const asset = '/eworldassets/assets/'

  const customerimg = '/eworldassets/customerimg/'
  const customerLogin = '/eworldassets/api/login/customer/index'
  const customerApi = '/eworldassets/api/customer/index'

  const adminLogin = '/eworldassets/api/login/admin/index'
  const adminUi = '/eworldassets/api/admin/ui'
  const adminApi = '/eworldassets/api/admin/index'

  return(
    <ThemeProvider theme={theme}>
    <Routes>
      <Route path='/login/*' element={<CustomerLogin customerLogin={customerLogin} />} />
      <Route path='/create-customer/:referurl' element={<CreateCustomer api={customerLogin} />} />
      <Route element={<Header imgpath={customerimg} api={customerApi} papi={publicApi} />}>
        <Route exact path="/*" element={<Landing asset={asset} product={product} api={publicApi} />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/can-ref' element={<CanRef />} />
        <Route path='/shipping' element={<Shipping />} />
        <Route path='/description/:productid' element={<Description api={publicApi} product={product} />} />
        <Route path='/search-result/:query' element={<SearchResult api={publicApi} product={product} />} />

        <Route path='/checkout/:productid' element={<Checkout api={customerApi} product={product} />} />
        
        <Route path='/profile/*' element={<UserProfile api={customerApi} imgpath={customerimg} />} />
          <Route path='/orders' element={<UserOrders product={product} api={customerApi} publicApi={publicApi} />} />

        <Route element={<UserHeader api={customerApi} imgpath={customerimg} />}>
          <Route path='refer/:tabno' element={<UserChain imgpath={customerimg} api={customerApi} />} />
          <Route path='address/:tabno' element={<UserAddress api={customerApi} />} />
        </Route>
      </Route>

      <Route exact path='/admin' element={<HeaderAdmin uiApi={adminUi} api={adminApi} asset={asset} />}>
        <Route element={<Sidebar api={adminApi} />}>
          <Route path='dashboard/*' element={<AdminDashboard api={adminApi} />} />
          <Route path='product/*' element={<AdminProduct product={product} imgpath={customerimg} api={adminApi} />} />
          <Route path='customer/*' element={<AdminCustomer product={product} asset={asset} api={adminApi} />} />
          <Route path='active-order/*' element={<AdminActiveOrder api={adminApi} product={product} />} />
          <Route path='order' element={<AdminOrder api={adminApi} />} />
          <Route path='redeem' element={<AdminRedeem api={adminApi} />} />
          <Route path='profile' element={<AdminProfile api={adminApi} asset={asset} />} />
        </Route>
      </Route>

      {/* PUBLIC */}
      <Route path='/invoice/:orderid' element={<Invoice api={publicApi} />} />

      <Route path='/verify-payment-user/:txnid' element={<UserVerifyPayment api={customerApi} />} />
      <Route path='/login-admin/*' element={<AdminLogin loginApi={adminLogin} uiApi={adminUi} />} />
    </Routes>
    </ThemeProvider>
  );
}
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<BrowserRouter><Eworld/></BrowserRouter>)