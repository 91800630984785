import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'

import AdminOrderPayment from "./pay";

// MUI
import { Alert, Box, Button, CircularProgress, Container, Dialog, DialogContent, FormControl, IconButton, Input, InputAdornment, InputLabel, LinearProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Receipt, Search, Warning, WarningAmber } from '@mui/icons-material'

export default function AdminActiveOrder({api, product}){
    let navi = useNavigate()
    const [orders, setOrders] = useState([])
    const [warning, setWarning] = useState('')
    const [loading, setLoading] = useState(true)

    const [searchQuery, setSearchQuery] = useState('')
    const search = () => {
        setWarning('')
        var formData = new FormData()
        formData.append('active_order', searchQuery)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setOrders(data)
            }
        })
    }

    useEffect(() => {
        search()
    }, [])

    const keyUpSearch = (event) => {
        setSearchQuery(event.target.value)
        if(event.keyCode === 13){
            search()
        }
    }

    const iconSearch = () => {
        search()
    }

    const payed = (event) => {
        if(event === 'success'){
            search()
        }
    }
    
    return(
        <Box className="content">
            <Container>
                <Stack spacing={2} direction='column' sx={{height: '89vh'}} >
                    <FormControl variant="standard" fullWidth >
                        <InputLabel htmlFor="Search orders" >Search orders</InputLabel>
                        <Input aria-label="Search orders" placeholder="Search by customer name, mobile" onKeyUp={(event) => keyUpSearch(event)} endAdornment={<InputAdornment position="end" ><IconButton disabled={searchQuery === '' ? true : false} onClick={() => iconSearch()} ><Search/></IconButton></InputAdornment>} />
                    </FormControl>

                    { loading ? <LinearProgress color="success" /> :
                    warning ? <Alert severity="warning">{warning}</Alert> :
                    <TableContainer component={Paper} elevation={0} sx={{flexGrow: 1}} >
                        <Table stickyHeader >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" >#</TableCell>
                                    <TableCell align="left" >CUSTOMER NAME</TableCell>
                                    <TableCell align="left" >PRODUCT</TableCell>
                                    <TableCell align="left" >PRICE</TableCell>
                                    <TableCell align="left" >ORDER DATE</TableCell>
                                    <TableCell align="left" >PAY</TableCell>
                                    <TableCell align="left">Deliver</TableCell>
                                    <TableCell align="left">Invoice</TableCell>
                                </TableRow>
                            </TableHead>
                            { orders.map((order, i) =>
                            <TableBody key={i} >
                                <TableRow>
                                    <TableCell align="left" >{ orders.length - i }</TableCell>
                                    <TableCell align="left" >{order.cf_name}</TableCell>
                                    <TableCell align="left" >{order.o_name}</TableCell>
                                    <TableCell align="left" >&#8377;{Number(order.o_price).toLocaleString('en-in')}</TableCell>
                                    <TableCell align="left" >{order.o_date}</TableCell>
                                    <TableCell align="left"><Button size="small" color='primary' variant="contained" disabled={order.o_status === 'Confirmed' ? true : false} onClick={() => navi(`order-payment/${order.orderid}`)} >PAY</Button></TableCell>
                                    <TableCell align="left" ><Button size="small" color="error" variant="contained" onClick={() => navi(`dilivered/${order.customerid}/${order.orderid}`)} disabled={order.o_status === 'Not_paid' && true} >Deliver</Button></TableCell>
                                    <TableCell><IconButton color='warning' onClick={() => navi(`/invoice/${order.orderid}`)} disabled={order.o_status === 'Not_paid' && true} ><Receipt/></IconButton></TableCell>
                                </TableRow>
                            </TableBody>) }
                        </Table>
                    </TableContainer>}
                </Stack>
            </Container>
        <Routes>
            <Route path="order-payment/:orderid" element={<AdminOrderPayment api={api} product={product} payed={(event) => payed(event)} />} />
            <Route path="dilivered/:customerid/:orderid" element={<Dilivered/>} />
        </Routes>
        </Box>
    );

    function Dilivered(){
        const { customerid } = useParams()
        const { orderid } = useParams()

        const [warning, setWarning] = useState('')
        const [working, setWorking] = useState(false)

        const mark_dilivered = () => {
            setWarning(''); setWorking(true)
            var formData = new FormData()
            formData.append('mark-order-dilivered', customerid)
            formData.append('orderid', orderid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    search(); navi(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth={true} maxWidth='xs' onClose={() => navi(-1)}>
                <DialogContent>
                    <Box>
                        <Box textAlign='center'>
                            <Warning color='warning' sx={{fontSize: 100}} />
                        </Box>
                        <Typography color='red' fontWeight='bold' align="center" fontSize={23} >Is this product dilivered?</Typography>
                        {warning && <Alert severity="warning">{warning}</Alert> }
                        <Box textAlign='center' mt={2} >
                            <Button color="error" variant="contained" disabled={working} onClick={() => mark_dilivered()} >{working ? <CircularProgress color="inherit" size={24} /> : 'Yes, Dilivered'}</Button>
                        </Box>

                        <Typography color='gray' variant="body2" mt={2} >*Kindly check it twice; you can't undo this process.</Typography>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

}