import React, { useEffect } from 'react'
import { } from 'react-router-dom'


// MUI
import { Card, CardContent, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material'
import { Call, LocationOn, Mail, WhatsApp } from '@mui/icons-material';

export default function Contact(){
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return(
        <Container sx={{marginTop: '2em'}} >
            <Grid container component={Paper} elevation={0} justifyContent='center' alignItems='center' mb={3} >
                <Grid item xs={12} md={7} padding={5} >
                    <Typography variant='h3' mb={5} >Contact us</Typography>
                    <img src='/assets/contact.svg' alt='Contact e-WORLD' />
                </Grid>
                <Grid item xs={12} md={5} padding={3}>
                    <List>
                        <ListItem>
                            <ListItemIcon><Call sx={{background: '#ec407a', color: 'white', fontSize: '2em', padding: '0.2em', borderRadius: 2}} /></ListItemIcon>
                            <a href='tel: 6203625936' rel='tag' ><ListItemText primary='Phone' secondary='6203625936' /></a>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><WhatsApp sx={{background: '#25D366', color: 'white', fontSize: '2em', padding: '0.2em', borderRadius: 2}} /></ListItemIcon>
                            <a href='https://wa.me/916203625936' target='_blank' rel="noreferrer" ><ListItemText primary='WhatsApp' secondary='6203625936' /></a>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><Mail sx={{background: '#E2E2E2', color: 'white', fontSize: '2em', padding: '0.2em', borderRadius: 2}} /></ListItemIcon>
                            <a href='Mailto: eworldshoping@gmail.com' rel='tag' ><ListItemText primary='Mail' secondary='eworldshoping@gmail.com' /></a>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><LocationOn sx={{background: '#fbc02d', color: 'white', fontSize: '2em', padding: '0.2em', borderRadius: 2}} /></ListItemIcon>
                            <a href='https://goo.gl/maps/VJdB6KwxbjptxCu19' target='_blank' rel="noreferrer" ><ListItemText primary='Location' secondary='Durgasthan, Katihar, Bihar' /></a>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>

            <Card elevation={0} >
                <CardContent>
                    <Typography variant='h5' >Our physical location</Typography>
                    <Typography variant='body1' mt={2} color='gray' >State Bank of India, NA, R.P. Path, Durga Sthan Chowk, Katihar <br/> Katihar<br/>Bihar, 854105</Typography>
                </CardContent>
            </Card>
        </Container>
    );
}