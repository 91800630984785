import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'

// MUI
import { Alert, Avatar, Button, CircularProgress, Dialog, DialogContent, Divider, Grid, Stack, Typography } from '@mui/material'
import { } from '@mui/icons-material'

export default function AdminOrderPayment({api, product, payed}){

    const { orderid } = useParams()
    const navi = useNavigate()

    const [order, setOrder] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('active-order-details', orderid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setOrder(data)
            }
        })
    }, [api, orderid])

    const [warning, setWarning] = useState('')
    const [working, setWorking] = useState(false)

    const paynow = () => {
        setWorking(true)
        var formData = new FormData()
        formData.append('confirm-order-payment', orderid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false)
            if(data.success){
                payed('success')
                navi(-1)
            } else {
                setWarning(data.warning)
            }
        })
    }

    return(
        <Dialog open={true} fullWidth={true} maxWidth='md' scroll="body" >
            <DialogContent>
                <Typography align="center" variant="h6" mb={2} >Order Payment</Typography><Divider/>

                <Grid container spacing={2} mt={2} mb={3} alignItems='center' justifyContent='center' >
                    <Grid item xs={12} md={3}>
                        <Avatar src={product+order.pimg} style={{width: 150, height: 150, borderRadius: 0}} />
                    </Grid>
                    <Grid item xs={12} md={9} >
                        <Typography color='gray' >Product Details</Typography>
                        <Typography typography='h6' >{order.o_name}</Typography>
                        <Typography variant="h5" mb={1} color='green' >&#8377;{Number(order.o_price).toLocaleString('en-in')}</Typography>
                        <Divider/>
                        <Typography color='gray' mt={1}>Buyer Details</Typography>
                        <Typography variant="body1" >{order.cf_name} {order.cl_name}</Typography>
                        <Typography variant="body2">Mobile: {order.c_mobile}</Typography>
                    </Grid>
                </Grid>

                <Divider/>
                <Typography color='gray' variant="caption" >*This is offline payment method</Typography>
                { warning && <Alert severity="warning" mt={2} >{warning}</Alert> }
                <Stack direction='row' spacing={1} mt={2} mb={2} justifyContent='center' >
                    <Button color="error" variant="text" sx={{borderRadius: 0.5, boxShadow: 0}} size='large' onClick={() => navi(-1)} >Cancel</Button>
                    <Button color="error" variant="contained" sx={{borderRadius: 0.5, boxShadow: 0}} size='large' disabled={working} onClick={() => paynow()} >{working ? <CircularProgress color="inherit" size={24} /> : 'PAY NOW'}</Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}