import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'

// MUI
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, TextField } from '@mui/material'

export default function AboutProductAdmin({api}){
    const navi = useNavigate()
    const { productid } = useParams()
    const { category } = useParams()

    const [abouts, setAbouts] = useState([])
    const [warning, setWarning] = useState('')
    const [uploading, setUploading] = useState(false)

    const upload = () => {
        setUploading(true); setWarning('')
        var formData = new FormData()
        formData.append('add-product-desc', productid)
        formData.append('abouts', JSON.stringify(abouts))

        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setUploading(false)
            if(data.warning){
                setWarning(data.warning)
            } else if(data.success){
                navi(-1)
            }
        })
    }

    const [proWarn, setProWarn] = useState('')
    useEffect(() => {
        var formData = new FormData()
        formData.append('product-desc', category)
        formData.append('productid', productid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setProWarn(data.warning)
            } else {
                setAbouts(data)
            }
        })
    }, [category, productid, api])

    const updateDesc = (i, event) => {
        var data = [...abouts]
        data[i].pd_value = event.target.value
        setAbouts(data)
    }

    return(
        <Dialog open={true} fullWidth={true} maxWidth='sm' onClose={() => navi(-1)}>
            <DialogTitle>Product Description</DialogTitle><Divider/>
            <DialogContent>
                {proWarn ? <Alert severity="warning">{proWarn}</Alert> :
                <Stack spacing={3} >
                    { abouts.map((a, i) =>
                    <TextField key={i} fullWidth variant="standard" label={a.pcd_name} value={a.pd_value == null ? undefined : a.pd_value} onChange={(event) => updateDesc(i, event)} /> ) }
                    { warning && <Alert severity="warning">{warning}</Alert> }
                </Stack>}
            </DialogContent>
            <Divider/><DialogActions>
                <Button color="inherit" variant="text" onClick={() => navi(-1)} >CLOSE</Button>
                <Button color="success" variant="text" onClick={() => upload()} disabled={uploading} >{uploading ? <CircularProgress color="inherit" size={24} /> : 'UPLOAD'}</Button>
            </DialogActions>
        </Dialog>
    );
}