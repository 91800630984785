import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'

// MUI
import { Alert, Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogContent, Divider, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material'
import { Assessment, BubbleChart, Category, History, Message, People, ShoppingCart, ShoppingCartCheckout, Today } from '@mui/icons-material'

export default function AdminDashboard({api}){
    let navi = useNavigate()

    const [orders, setOrders] = useState([])
    const [warning, setWarning] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setWarning('')
        var formData = new FormData()
        formData.append('orderHistory', '')
        formData.append('type', 'Confirmed')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setOrders(data)
            }
        })
    }, [api])

    const [dash, setDash] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('dashboard', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setDash(data)
            }
        })
    }, [api])

    const [oweek, setOweek] = useState([])
    const [oweekWarn, setOweekWarn] = useState('')
    useEffect(() => {
        var formData = new FormData()
        formData.append('orderWeekly', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setOweekWarn(data.warning)
            } else {
                setOweek(data)
            }
        })
    }, [api])

    const [redeemrequest, setRedeemrequest] = useState([])
    const [requestwarn, setRequestwarn] = useState('')
    const [requestload, setRequestload] = useState(true)
    useEffect(() => {
        load_redeem_request()
    }, [])
    const load_redeem_request = () => {
        const get_request = localStorage.getItem('redeem-request-admin')
        get_request !== null && setRedeemrequest(JSON.parse(get_request))
        var formData = new FormData()
        formData.append('customer-redeem-request', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setRequestload(false)
            if(data.warning){
                setRequestwarn(data.warning)
            } else {
                localStorage.setItem('redeem-request-admin', JSON.stringify(data))
                const get_request = localStorage.getItem('redeem-request-admin')
                get_request !== null && setRedeemrequest(JSON.parse(get_request))
            }
        })
    }

    return(
        <Box className="content" style={{paddingBottom: '1em'}}>
            <Container>
                <Grid container mt={1} component={Paper} p={2} >
                    <Grid item xs={6} md={3} sx={{textAlign: 'center'}} >
                        <People sx={{fontSize: '3em', padding: 1, borderRadius: 1, color: '#ec407a'}} /><Divider/>
                        <Typography variant="caption" color='gray' >Customers / Pending</Typography>
                        <Typography variant="h4" >{dash.customers}/{Number(dash.pending_customer)}</Typography>
                    </Grid>
                    <Grid item xs={6} md={3} sx={{textAlign: 'center'}} >
                        <Category sx={{fontSize: '3em', padding: 1, borderRadius: 1, color: '#ab47bc'}} /><Divider/>
                        <Typography variant="caption" color='gray' >Products</Typography>
                        <Typography variant="h4" >{dash.products}</Typography>
                    </Grid>
                    <Grid item xs={6} md={3} sx={{textAlign: 'center'}} >
                        <ShoppingCart sx={{fontSize: '3em', padding: 1, borderRadius: 1, color: '#42a5f5'}} /><Divider/>
                        <Typography variant="caption" color='gray' >Orders</Typography>
                        <Typography variant="h4" >{dash.orders}</Typography>
                    </Grid>
                    <Grid item xs={6} md={3} sx={{textAlign: 'center'}} >
                        <ShoppingCartCheckout sx={{fontSize: '3em', padding: 1, borderRadius: 1, color: '#26c6da'}} /><Divider/>
                        <Typography variant="caption" color='gray' >Active Orders</Typography>
                        <Typography variant="h4" >{dash.active_orders}</Typography>
                    </Grid>
                </Grid>

                <Grid container mt={0} spacing={2}>
                    <Grid item xs={12} md={7}>
                        <Box padding={2} height={300} component={Paper}  overflow='auto' >
                            <Typography variant="body1" color='gray' mb={1} >Coin redeem request</Typography>
                            <TableContainer>
                                { requestload ? <LinearProgress color="primary" /> :
                                requestwarn ? <Alert severity="warning">{requestwarn}</Alert> :
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Customer</TableCell>
                                            <TableCell>Mobile</TableCell>
                                            <TableCell>Coin</TableCell>
                                            <TableCell>Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        { redeemrequest.map((data, i) =>
                                        <TableRow key={i} sx={{cursor: 'pointer'}} onClick={() => navi(`request/${data.customerid}/${data.rowid}/${data.name}/${data.coin}`)} >
                                            <TableCell>{data.name}</TableCell>
                                            <TableCell>{data.c_mobile}</TableCell>
                                            <TableCell>{data.coin}</TableCell>
                                            <TableCell>{data.coin_date}</TableCell>
                                        </TableRow> ) }
                                    </TableBody>
                                </Table>}
                            </TableContainer>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Box height={{xs: 'auto', md: 300}} padding={2} component={Paper}>
                            <Toolbar>
                                <Today sx={{fontSize: '2.5em'}} color='warning' />
                                <Typography variant="body2" color='gray' flexGrow={1} >Sold today</Typography>
                                <Typography fontWeight='bold' fontSize={20} >&#8377;{Number(dash.saleToday).toLocaleString('en-in')}</Typography>
                            </Toolbar><Divider/>
                            <Toolbar>
                                <History sx={{fontSize: '2.5em'}} color='info' />
                                <Typography variant="body2" color='gray' flexGrow={1} >Sold total</Typography>
                                <Typography fontWeight='bold' fontSize={20} >&#8377;{Number(dash.saleTotal).toLocaleString('en-in')}</Typography>
                            </Toolbar>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container mt={0} spacing={3} >
                    <Grid item xs={12} md={6}>
                        <Card  sx={{height: 400}} >
                            <CardContent>
                                <Typography variant="body1" color='gray' mb={1} >Active Orders ({orders.length})</Typography>
                                { loading ? <LinearProgress color="success" /> :
                                warning ? <Alert severity="warning">{warning}</Alert> :
                                <TableContainer sx={{maxHeight: 310}} >
                                    <Table stickyHeader size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Customer</TableCell>
                                                <TableCell>Product</TableCell>
                                                <TableCell>Price</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            { orders.map((order, i) =>
                                            <TableRow key={i} >
                                                <TableCell>{order.cf_name} {order.cl_name}</TableCell>
                                                <TableCell>{order.o_name}</TableCell>
                                                <TableCell>&#8377;{Number(order.o_price).toLocaleString('en-in')}</TableCell>
                                            </TableRow>) }
                                        </TableBody>
                                    </Table>
                                </TableContainer>}
                                <Typography align="center" >
                                    <Button variant="text" color="secondary" onClick={() => navi('/admin/active-order')} >VIEW ALL</Button>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card  sx={{height: 400}} >
                            <CardContent>
                                <Typography variant="body1" color='gray' >Orders (Weekly) ({oweek.length})</Typography>
                                { oweekWarn ? <Alert severity="warning">{oweekWarn}</Alert> :
                                <TableContainer sx={{maxHeight: 310}} >
                                    <Table stickyHeader size="small" >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left" >Customer Name</TableCell>
                                                <TableCell align="left" >Product</TableCell>
                                                <TableCell align="left" >Price</TableCell>
                                                <TableCell align="left" >Dilivered</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        { oweek.map((order, i) =>
                                        <TableBody key={i} >
                                            <TableRow>
                                                <TableCell align="left" >{order.cf_name}</TableCell>
                                                <TableCell align="left" >{order.o_name}</TableCell>
                                                <TableCell align="left" >&#8377;{Number(order.o_price).toLocaleString('en-in')}</TableCell>
                                                <TableCell align="left" >{order.o_date}</TableCell>
                                            </TableRow>
                                        </TableBody>) }
                                    </Table>
                                </TableContainer>}
                                <Typography align="center" >
                                    <Button variant="text" color="secondary" onClick={() => navi('/admin/order')} >VIEW ALL</Button>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                
                <Grid container spacing={3} mt={0} >
                    <Grid item xs={12} md={4}>
                        <Card  >
                            <CardContent>
                                <Typography color='gray' variant="body1" ml={1} >Sold Products</Typography>
                                <Stack direction='row' mt={2} >
                                    <Assessment sx={{fontSize: '4em', color: '#8d6e63'}} />
                                    <Typography sx={{flexGrow: 1}}></Typography>
                                    <Typography variant="h5" mt={2} >{dash.dilivered_orders}</Typography>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card  >
                            <CardContent>
                                <Typography color='gray' variant="body1" ml={1} >Coin Distributed</Typography>
                                <Stack direction='row' mt={2} >
                                    <BubbleChart sx={{fontSize: '4em', color: '#00bfa5'}} />
                                    <Typography sx={{flexGrow: 1}}></Typography>
                                    <Typography variant="h5" mt={2} >{dash.coin_distributed}</Typography>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card  >
                            <CardContent>
                                <Typography color='gray' variant="body1" ml={1} >Message Left</Typography>
                                <Stack direction='row' mt={2} >
                                    <Message sx={{fontSize: '4em', color: '#00b0ff'}} />
                                    <Typography sx={{flexGrow: 1}}></Typography>
                                    <Typography variant="h5" mt={2} >{dash.msg_left}</Typography>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            </Container>
            <Routes>
                <Route path="request/:customerid/:rowid/:name/:coins" element={<Redeem_request />} />
            </Routes>
        </Box>
    );

    function Redeem_request(){

        const { customerid } = useParams()
        const { rowid } = useParams()
        const { name } = useParams()
        const { coins } = useParams()

        const [warning, setWarning] = useState('')
        const [working, setWorking] = useState(false)

        const redeem_row = () => {
            setWarning(''); setWorking(true)
            var formData = new FormData()
            formData.append('redeem-request', customerid)
            formData.append('rowid', rowid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setWorking(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    load_redeem_request()
                    navi(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth={true} maxWidth='xs' onClose={() => navi(-1)}>
                <DialogContent>
                    <Box textAlign='center' pt={2} pb={2} >
                        <Typography fontSize={20} fontWeight='bold' color='orange' >You are redeeming '{coins}' coins of '{name}'.</Typography>
                        <Typography mt={1} mb={1} >Are you sure to do it?</Typography>
                        <Typography color='red' variant="caption" >Note: You can't undo this process.</Typography>

                        {warning && <Alert severity="warning">{warning}</Alert>}

                        <Box textAlign='center' mt={2} >
                            <Button variant="contained" color="primary" disabled={working} onClick={() => redeem_row()} >{working ? <CircularProgress color="inherit" size={24} /> : 'Yes, Confirm'}</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

}