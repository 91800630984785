import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';

import Footer from './footer';

// MUI
import { Alert, AppBar, Avatar, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, Divider, IconButton, InputBase, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import SearchIcon from '@mui/icons-material/Search'

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 5,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '100ch',
      },
    },
}));


export default function Header({imgpath, api, papi}) {
    const [logDia, setLogDia] = useState(false)
    const navi = useNavigate()

    const navLink = [{'page':'Orders', 'link':'/orders'}]
    const userLink = [{'page':'My profile', 'link':'/profile'}]
    
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    function cookie(cname){
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    const [profile, setProfile] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('profile', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                var cookies = document.cookie.split(";");
                for (var i = 0; i < cookies.length; i++) {
                    var cookie = cookies[i];
                    var eqPos = cookie.indexOf("=");
                    var cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                    document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                }
            } else {
                setProfile(data)
                localStorage.setItem('profile', JSON.stringify(data))
            }
        })
    }, [api])

    const [logoutWarn, setLogoutWarn] = useState('')
    const [loggingout, setLoggingout] = useState(false)
    const logout = () => {
        setLoggingout(true)
        var formData = new FormData()
        formData.append('logout', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoggingout(false)
            if(data.success){
                setLogDia(false)
                navi('/')
            } else {
                setLogoutWarn(data.warning)
            }
        })
    }

    const search = (event) => {
        if(event.keyCode === 13){
            navi(`/search-result/${event.target.value}`)
        }
    }

    return(
        <Box>
        <AppBar position='sticky' elevation={1} >
            <Container>
                <Toolbar disableGutters >
                    <Box>
                        <Link to='/'><img src='/logo.svg' style={{width: 50, height: 30, marginTop: '5px'}} alt='Brand logo' /></Link>
                    </Box>
                    <Box sx={{flexGrow: 2}} pr={1}>
                        <Search>
                            <SearchIconWrapper> <SearchIcon /> </SearchIconWrapper>
                            <StyledInputBase placeholder="Search e-WORLD" onKeyUp={(event) => search(event)} inputProps={{ 'aria-label': 'search' }} />
                        </Search>
                    </Box>
                    { cookie('true-customer') === '1' &&
                    <Box sx={{display: {xs: 'none', md: 'block'} }} >
                        {navLink.map((nav, i) =>
                        <Button sx={{color: 'white'}} key={i} ><Link to={`${nav.link}`} style={{color: 'white'}} >{nav.page}</Link></Button>)}
                    </Box> }
                    { cookie('true-customer') === '1' &&
                    <Box>
                        <IconButton onClick={handleOpenUserMenu}>
                            <Avatar src={`/assets/${profile.c_gender === 'Male' ? 'male.svg' : 'female.svg'}`} alt='Avatar' />
                        </IconButton>
                        <Menu anchorEl={anchorElUser} anchorOrigin={{vertical: 'top', horizontal: 'right'}} keepMounted transformOrigin={{vertical: 'top', horizontal: 'right' }} open={Boolean(anchorElUser)} onClose={handleCloseUserMenu} >
                            { navLink.map((nav, i) =>
                                <MenuItem key={i} sx={{display: {sx: 'block', md: 'none'}}} onClick={() => handleCloseUserMenu()} ><Link to={`${nav.link}`}>{nav.page}</Link></MenuItem>
                            )}
                            { userLink.map((user, i) =>
                            <MenuItem key={i} onClick={() => handleCloseUserMenu()} ><Link to={`${user.link}`}>{user.page}</Link></MenuItem>) }
                            <MenuItem onClick={() => setLogDia(true)} >Logout</MenuItem>
                        </Menu>
                    </Box> }
                    { !cookie('true-customer') &&
                    <Box>
                        <Link to='/login' rel='tag' ><Button sx={{color: 'black', boxShadow: 'none', background: 'white'}} variant='contained' color='inherit'>LOGIN</Button></Link>
                    </Box> }
                </Toolbar>
            </Container>
        </AppBar>
        <Outlet context={{profile}} />
        <Footer api={papi} />
        <Dialog open={logDia} fullWidth={true} maxWidth='xs' onClose={() => setLogDia(false)}>
            <DialogContent sx={{margin: '0.5em 0 0.5em 0'}}>
                <Typography variant="h4" mb={2} >Do you want to logout?</Typography>
                {logoutWarn && <Alert severity='warning' >{logoutWarn}</Alert> }
            </DialogContent><Divider />
            <DialogActions>
                <Button color="inherit" onClick={() => setLogDia(false)}>NO</Button>
                <Button color="secondary" onClick={() => logout()} disabled={loggingout} >{loggingout ? <CircularProgress color='inherit' size={24} /> : 'YES'}</Button>
            </DialogActions>
        </Dialog>
        </Box>
    );
}