import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'

// MUI
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, TextField } from '@mui/material'
import { } from '@mui/icons-material'

export default function AddressCustomer({api, response}){
    const navi = useNavigate()
    const { customerid } = useParams()
    const { addressid } = useParams()

    const [name, setName] = useState('')
    const [mobile, setMobile] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [postal, setPostal] = useState('')
    const [address, setAddress] = useState('')
    const [warning, setWarning] = useState('')
    const [uploading, setUploading] = useState(false)

    useEffect(() => {
        var formData = new FormData()
        formData.append('address-details', addressid)
        formData.append('customerid', customerid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setName(data.ca_name); setMobile(data.ca_mobile); setState(data.ca_state); setCity(data.ca_city); setPostal(data.ca_postal); setAddress(data.ca_address)
            }
        })
    }, [addressid, customerid, api])

    const upload = () => {
        setUploading(true); setWarning('')
        var formData = new FormData()
        formData.append('configure-address', addressid === 'null' ? '' : addressid )
        formData.append('customerid', customerid)
        formData.append('name', name)
        formData.append('state', state)
        formData.append('city', city)
        formData.append('postal', postal)
        formData.append('mobile', mobile)
        formData.append('address', address)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setUploading(false)
            if(data.success){
                response('success')
            } if(data.warning){
                setWarning(data.warning)
            }
        })
    }
    
    return(
        <Dialog open={true} fullWidth={true} maxWidth='xs' onClose={() => navi(-1)} >
            <DialogTitle>Address</DialogTitle><Divider/>
            <DialogContent>
                <Stack spacing={2} mb={1} >
                    <TextField fullWidth variant="standard" label='Full name' type='text' value={name} onChange={(event) => setName(event.target.value)} />
                    <TextField fullWidth variant="standard" label='Mobile' type='number' value={mobile} onChange={(event) => setMobile(event.target.value)} />
                    <TextField fullWidth variant="standard" label='State' type='text' value={state} onChange={(event) => setState(event.target.value)} />
                    <TextField fullWidth variant="standard" label='City' type='text' value={city} onChange={(event) => setCity(event.target.value)} />
                    <TextField fullWidth variant="standard" label='Postal code' type='number' value={postal} onChange={(event) => setPostal(event.target.value)} />
                    <TextField fullWidth variant="standard" label='Address' type='text' multiline rows={3} value={address} onChange={(event) => setAddress(event.target.value)} />
                </Stack>
                { warning && <Alert severity="warning">{warning}</Alert> }
            </DialogContent><Divider/>
            <DialogActions>
                    <Button variant="text" color="inherit" onClick={() => navi(-1)} >CLOSE</Button>
                    <Button variant="text" color="success" disabled={uploading} onClick={() => upload()} >{uploading ? <CircularProgress color="inherit" size={24} /> : 'UPDATE' }</Button>
            </DialogActions>
        </Dialog>
    );
}