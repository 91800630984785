import React, { useEffect } from 'react'
import { } from 'react-router-dom'

// MUI
import { Card, CardContent, Container, Typography } from '@mui/material'

export default function CanRef(){
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return(
        <Container sx={{marginTop: '2em'}} >
            <Card>
                <CardContent>
                    <Typography variant='h4' mt={2} >CANCELLATION & REFUNDS POLICY</Typography>
                    <Typography variant='body1' mt={2}>
                    The cancellation policy is the scheme provided on E-WORLD website available at www..com or the mobile application under the brand name “E-WORLD” (collectively “Platform”) in relation to the products being sold on the Platform (“Products”).  This cancellation policy (“Policy”) lays down the mechanism in which an order placed by the customer or the reseller (“User”) on the Platform can be cancelled by the User or the supplier or by E-WORLD.
                    </Typography>

                    <Typography variant='h4' mt={2} >Cancellation by the User</Typography>
                    <Typography variant='h4' mt={2}>When can the User cancel an order?</Typography>
                    <Typography variant='body1' mt={2} >
                    The User can cancel an order after the order has been placed through the Platform and before the order has been dispatched by the supplier.
                    </Typography>

                    <Typography variant='h4' mt={2}>What are the reasons due to which the User may cancel the order?</Typography>
                    <Typography variant='body1' mt={2}>
                    •	If the address to which the Product is to be delivered is not serviceable;
                    •	If the Product has been lost during transit;
                    •	If the payment has made through online methods by the User and E-WORLD did not get payment confirmation;
                    •	If the shipping address or billing address provided by the User is incomplete or incorrect;
                    •	If the supplier is unable to dispatch the order because of regulatory lockdown or other restrictions;
                    •	If the order has been auto cancelled by the system due to high risk of product returning back to the supplier;
                    •	If the supplier does not meet E-WORLD’s policies of fair business practices;
                    •	If E-WORLD does not get user confirmation during delivery;
                    •	If the User chose cash on delivery and cash on delivery is not available for the Product ordered;
                    •	If E-WORLD is unable to fulfil the order due to other reasons; or 
                    •	If E-WORLD wants to cancel for any reason which E-WORLD may deem appropriate.
                    </Typography>

                    <Typography variant='h4' mt={2}>Refunds</Typography>
                    <Typography variant='h4' mt={2}>How will the User get the refund?</Typography>
                    <Typography variant='body1' mt={2}>
                    If the User has made payment before cancellation of the order which has been placed, the money paid by the User shall be refunded. The refund will be credited to the same source of payment (bank accounts, UPI etc.) from which payment was received. If the User has chosen any other method such as wallets, the mount will be credited to the source of origin as well. The User will receive a refund of the entire amount paid by the User. In case of any discrepancies regarding receipt of refund amount, E-WORLD may request for additional information such as bank statement or any other relevant document.
                    </Typography>

                    <Typography variant='h4' mt={2}>Will the discount vouchers or other such promotional offers be reinstated?</Typography>
                    <Typography variant='body1' mt={2}>
                    If the User has used any discount vouchers or promotional offers when placing the order which has been cancelled, the discount vouchers or promotional offers will be forfeited.
                    </Typography>

                    <Typography variant='h4' mt={2}>Miscellaneous</Typography>
                    <Typography variant='body1' mt={2}>
                    E-WORLD reserves the right, at its sole discretion, to amend, change, modify, add or remove any portion of this policy at any time without any prior written notice to you. It is your responsibility to review this policy periodically for any updates/ changes.

                    For any further queries regarding return, replacement, exchange or refund, please reach out to customer.
                    </Typography>

                </CardContent>
            </Card>
        </Container>
    );
}