import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom'

// MUI
import { Avatar, Badge, Box, Button, CircularProgress, Container, Dialog, DialogContent, IconButton, Snackbar, Stack, Tab, Tabs, Typography } from '@mui/material'
import { BubbleChart, CameraAlt, LockClock } from '@mui/icons-material'

export default function UserHeader({api, imgpath}){
    var { tabno } = useParams()
    const navigate = useNavigate()

    function cookie(cname){
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
        !cookie('true-customer') && navigate('/login', {replace: true})
    }, [navigate])

    useEffect(() => {
        setValue(tabno)
    }, [tabno])

    const [value, setValue] = useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const {profile} = useOutletContext()

    const [uploading, setUploading] = useState(false)
    const [openSnack, setOpenSnack] = useState(false)
    const [snackMess, setSnackMess] = useState('')
    const uploadPic = (event) => {
        setUploading(true)
        var formData = new FormData()
        formData.append('update-pic', event.target.files[0])
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setUploading(false)
            if(data.success){
                window.location.reload()
            } else {
                setSnackMess(data.warning)
                setOpenSnack(true)
            }
        })
    }

    return(
        <><Container>
            <Snackbar open={openSnack} message={snackMess} onClose={() => setOpenSnack(false)} />
            <Box sx={{width: '100%', textAlign: 'center'}} mt={4}>
                <Badge overlap="circular" anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} badgeContent={
                    <IconButton component='label' >
                        { uploading ? <CircularProgress color="inherit" size={20} /> :
                        <>
                            <input hidden accept=".jpeg, .jpg, .png" type='file' onChange={(event) => uploadPic(event)} />
                            <CameraAlt />
                        </>}
                    </IconButton>
                }>
                    <Avatar src={imgpath+profile.c_img} sx={{width: 190, height: 190, }} className='shadow' />
                </Badge>
            </Box>

            <Box sx={{width: 200, textAlign: 'center', margin: 'auto'}} pb={2} pt={3} >
                <Button startIcon={<BubbleChart style={{fontSize: '2em'}} />} className='bgcolor' sx={{width: 200, color: '#fafafa', padding: '0.2em', fontSize: '20px'}} >{Number(profile.coins)}</Button>
            </Box>

            <Box sx={{width: '100%', bgcolor: 'background.paper'}}>
                <Tabs value={Number(value)} onChange={(event) => handleChange(event)} variant='fullWidth' centered>
                    <Tab label="Orders" value={0} onClick={() => navigate('orders/0')} />
                    <Tab label="Referred" value={1} onClick={() => navigate('refer/1')} />
                    <Tab label="Profile" value={2} onClick={() => navigate('profile/2')} />
                    <Tab label="Address"value={3} onClick={() => navigate('address/3')} />
                </Tabs>
            </Box>

            <Dialog open={profile.c_status === 'Pending' ? true : false } fullWidth={true} maxWidth='sm' onClose={() => navigate('/')} PaperProps={{style: {borderRadius: 20}}} >
                <DialogContent>
                    <Stack direction='column' alignItems='center' justifyContent='space-between' spacing={2} pt={5} pb={5} >
                        <LockClock color="warning" sx={{fontSize: '5em'}} />
                        <Typography variant="h5" align="center" >We are reviewing your account.</Typography>
                        <Typography variant="caption" >Status: Pending</Typography>

                        <Button color="warning" variant="contained" sx={{boxShadow: 0, padding: '0.5em 2.5em 0.5em 2.5em'}} onClick={() => navigate('/')} >OK</Button>
                    </Stack>
                </DialogContent>
            </Dialog>
        </Container> <Outlet context={{profile}} /> </>
    );
}