import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'


// MUI
import { Box, Button, Card, CardContent, CardMedia, Container, Grid, Stack, Toolbar, Typography } from '@mui/material'
import {  } from '@mui/icons-material'

export default function Landing({asset, product, api}){
    const navi = useNavigate()

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    const [phones, setPhones] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('products', 'Mobile')
        formData.append('limit', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){

            } else {
                localStorage.setItem('index-phone', JSON.stringify(data))
                const getPhones = localStorage.getItem('index-phone')
                getPhones && setPhones(JSON.parse(getPhones))
            }
        })
    }, [api])

    const [refrigerators, setRefrigerators] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('products', 'Refrigerator')
        formData.append('limit', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setRefrigerators(data)
            }
        })
    }, [api])

    const [washingMachines, setWashingMachines] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('products', 'Whashing Machine')
        formData.append('limit', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setWashingMachines(data)
            }
        })
    }, [api])

    const [speakers, setSpeakers] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('products', 'Speaker')
        formData.append('limit', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setSpeakers(data)
            }
        })
    }, [api])

    const [inverters, setInverters] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('products', 'Inverter')
        formData.append('limit', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setInverters(data)
            }
        })
    }, [api])

    const [tv, setTv] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('products', 'TV')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setTv(data)
            }
        })
    }, [api])

    const [ac, setAc] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('products', 'Air Conditioner')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setAc(data)
            }
        })
    }, [api])

    const [chimney, setChimney] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('products', 'Chimney')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setChimney(data)
            }
        })
    }, [api])

    const [mixer, setMixer] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('products', 'Mixer Grinder')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setMixer(data)
            }
        })
    }, [api])

    return(
        <>
        <Container>
            <Grid direction={{xs: 'column-reverse', md: 'row'}} container mt={2} mb={3} justifyContent='start' alignItems={{md: 'center', xs: 'start'}} >
                <Grid item xs={12} md={4}>
                    <Typography fontSize={28} fontWeight='bold'  >Bringing Home Comfort:</Typography>
                    <Typography fontSize={28} fontWeight='bold'  >Your One-Stop Appliance</Typography>
                    <Typography fontSize={28} fontWeight='bold'  >Shop!</Typography>
                    <Typography fontSize={35} fontWeight='bold' color='red' >0% EMI</Typography>

                    <Box mt={3} >
                        <Button variant="contained" sx={{borderRadius: 0, backgroundColor: '#FFC40C'}} color="secondary" onClick={() => window.location.href='#explore_here'} >Explore products</Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={8}>
                    <img src={`/assets/eworld-1.webp`} alt='e-WORLD welcome landing'  style={{borderRadius: 20}} />
                </Grid>
            </Grid>
        </Container>

        <Container>
            <Grid container spacing={2} >
                <Grid item xs={6} md={3} onClick={() => navi('/search-result/Mobile')} >
                    <Card variant="outlined" >
                        <CardMedia
                            component="img"
                            image={`${asset}/mobile-cate.webp`}
                            alt="Mobile category"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div" noWrap>
                            Mobiles
                            </Typography>
                            <Typography variant="body2" color="text.secondary" noWrap>
                            Redmi, Oppo, Vivo, Samsung, Nokia
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3} onClick={() => navi('/search-result/Refrigerator')} >
                    <Card variant="outlined">
                        <CardMedia
                            component="img"
                            image={`${asset}/refrigerators.webp`}
                            alt="Mobile category"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div" noWrap>
                            Refrigerators
                            </Typography>
                            <Typography variant="body2" color="text.secondary" noWrap>
                            Samsung, Godrej, Whirlpool, LG
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3} onClick={() => navi('/search-result/Washing Machine')} >
                    <Card variant="outlined">
                        <CardMedia
                            component="img"
                            image={`${asset}/washing-machine.webp`}
                            alt="Mobile category"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div" noWrap>
                            Whashing machines
                            </Typography>
                            <Typography variant="body2" color="text.secondary" noWrap>
                            Godrej, Whirlpool, LG, Panasonic
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3} onClick={() => navi('/search-result/Speaker')} >
                    <Card variant="outlined">
                        <CardMedia
                            component="img"
                            image={`${asset}/Home-theatre.webp`}
                            alt="Mobile category"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div" noWrap>
                            Speakers
                            </Typography>
                            <Typography variant="body2" color="text.secondary" noWrap >
                            Make some sound with Sony, JBL, Zebronics
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3} onClick={() => navi('/search-result/Inverter')} >
                    <Card variant="outlined">
                        <CardMedia
                            component="img"
                            image={`${asset}/inverter.webp`}
                            alt="Mobile category"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div" noWrap>
                            Inverters
                            </Typography>
                            <Typography variant="body2" color="text.secondary" noWrap >
                            Light your home, office with Luminous, Exide, V-Guard
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3} onClick={() => navi('/search-result/tv')} >
                    <Card variant="outlined">
                        <CardMedia
                            component="img"
                            image={`${asset}/tv.webp`}
                            alt="TV category"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div" noWrap>
                            TVs
                            </Typography>
                            <Typography variant="body2" color="text.secondary" noWrap >
                            Fill things real with BPL, Samsung, Sony
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3} onClick={() => navi('/search-result/ac')} >
                    <Card variant="outlined">
                        <CardMedia
                            component="img"
                            image={`${asset}/AC.webp`}
                            alt="TV category"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div" noWrap>
                            Air Conditioners
                            </Typography>
                            <Typography variant="body2" color="text.secondary" noWrap >
                            Beat the heat with BPL, Haier, Godreg
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} md={3} onClick={() => navi('/search-result/chimney')} >
                    <Card variant="outlined">
                        <CardMedia
                            component="img"
                            image={`${asset}/chimney.webp`}
                            alt="TV category"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div" noWrap>
                            Kitchen Appliances
                            </Typography>
                            <Typography variant="body2" color="text.secondary" noWrap >
                            Cook food with love using Mixer, Chimney, Sandwich Griller
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Box mt={4} mb={3} sx={{background: '#F5F5F5'}} p={{xs: 2, md: 3}} borderRadius={1.5} >
                <Stack direction='row' justifyContent='space-between' alignItems='center' mt={3} mb={3} >
                    <Typography fontWeight='bold' >Order now for fast delivery</Typography>

                    <Button variant="outlined" size="large" color="secondary" onClick={() => window.location.href='tel: 6203625936'} >Order now</Button>
                </Stack>
            </Box>

            { phones.length > 0 &&
            <Box mt={4} id='explore_here' >
                <Toolbar style={{padding: 0}}>
                    <Typography sx={{flexGrow: 1}} fontSize={16}  >Mobile phones</Typography>
                    <Button onClick={() => navi('/search-result/Mobile')} color="secondary" variant="outlined" size="small" >View all</Button>
                </Toolbar>
                <Grid container spacing={2}>
                    {phones.map((data, i) =>
                    <Grid item xs={12} md={2.4} key={i} onClick={() => navi(`/description/${data.productid}`)} >
                        <Stack pb={1} pt={1} direction={{md: 'column', xs: 'row'}} sx={{border: '1.3px solid #BEBFC5', cursor: 'pointer'}} borderRadius={3} height={{md: 380, xs: 'auto'}} justifyContent='start' alignItems={{xs: 'center', md: 'start'}} >
                            <Box padding={3} sx={{width: {md: 'auto', xs: '30%'}}} >
                                <img src={`${product}/${data.pimg}`} alt={data.p_name} />
                            </Box>
                            <Box pl={1.5} pr={1.5} mb={1} sx={{width: {md: 'auto', xs: '70%'}}} >
                                <Typography fontSize={14} className="wrap-2" >{data.p_name}</Typography>
                                <Stack direction='row' alignItems='center' mt={1} >
                                    <Typography fontSize={20} fontWeight='bold' noWrap >&#8377; {Number(data.p_price).toLocaleString('en-in')}&nbsp;&nbsp;</Typography>
                                    <Typography fontSize={16} sx={{textDecoration: 'line-through', color: '#848482'}} >&#8377; {Number(data.p_mrp).toLocaleString('en-in')}</Typography>
                                </Stack>
                                <Typography color='green' fontWeight='bold' >{ Number((Number(data.p_mrp) - (Number(data.p_price))) *100/Number(data.p_mrp)).toFixed(0) }% off</Typography>
                                {data.p_emi == '1' ? <Typography fontSize={12} color='red' mt={1} >0% EMI available</Typography> : ''}
                            </Box>
                        </Stack>
                    </Grid>)}
                </Grid>
            </Box> }
            
            { refrigerators.length > 0 &&
            <Box mt={4} >
                <Toolbar style={{padding: 0}}>
                    <Typography sx={{flexGrow: 1}} fontSize={16} >Refrigerators</Typography>
                    <Button onClick={() => navi('/search-result/Refrigerator')} color="secondary" variant="outlined" size="small" >View all</Button>
                </Toolbar>
                <Grid container spacing={2}>
                    {refrigerators.map((data, i) =>
                    <Grid item xs={12} md={2.4} key={i} onClick={() => navi(`/description/${data.productid}`)} >
                        <Stack pb={1} pt={1} direction={{md: 'column', xs: 'row'}} sx={{border: '1.3px solid #BEBFC5', cursor: 'pointer'}} borderRadius={3} height={{md: 380, xs: 'auto'}} justifyContent='start' alignItems={{xs: 'center', md: 'start'}} >
                            <Box padding={3} sx={{width: {md: 'auto', xs: '30%'}}} >
                                <img src={`${product}/${data.pimg}`} alt={data.p_name} />
                            </Box>
                            <Box pl={1.5} pr={1.5} mb={1} sx={{width: {md: 'auto', xs: '70%'}}} >
                                <Typography fontSize={14} className="wrap-2" >{data.p_name}</Typography>
                                <Stack direction='row' alignItems='center' mt={1} >
                                    <Typography fontSize={20} fontWeight='bold' noWrap >&#8377; {Number(data.p_price).toLocaleString('en-in')}&nbsp;&nbsp;</Typography>
                                    <Typography fontSize={16} sx={{textDecoration: 'line-through', color: '#848482'}} >&#8377; {Number(data.p_mrp).toLocaleString('en-in')}</Typography>
                                </Stack>
                                <Typography color='green' fontWeight='bold' >{ Number((Number(data.p_mrp) - (Number(data.p_price))) *100/Number(data.p_mrp)).toFixed(0) }% off</Typography>
                                <Typography fontSize={12} color='red' mt={1} >0% EMI available</Typography>
                            </Box>
                        </Stack>
                    </Grid>)}
                </Grid>
            </Box> }
            
            { washingMachines.length > 0 &&
            <Box mt={4} >
                <Toolbar style={{padding: 0}}>
                    <Typography sx={{flexGrow: 1}} fontSize={16} >Washing Machines</Typography>
                    <Button onClick={() => navi('/search-result/Washing Machine')} color="secondary" variant="outlined" size="small" >View all</Button>
                </Toolbar>
                <Grid container spacing={2}>
                    {washingMachines.map((data, i) =>
                    <Grid item xs={12} md={2.4} key={i} onClick={() => navi(`/description/${data.productid}`)} >
                        <Stack pb={1} pt={1} direction={{md: 'column', xs: 'row'}} sx={{border: '1.3px solid #BEBFC5', cursor: 'pointer'}} borderRadius={3} height={{md: 380, xs: 'auto'}} justifyContent='start' alignItems={{xs: 'center', md: 'start'}} >
                            <Box padding={3} sx={{width: {md: 'auto', xs: '30%'}}} >
                                <img src={`${product}/${data.pimg}`} alt={data.p_name} />
                            </Box>
                            <Box pl={1.5} pr={1.5} mb={1} sx={{width: {md: 'auto', xs: '70%'}}} >
                                <Typography fontSize={14} className="wrap-2" >{data.p_name}</Typography>
                                <Stack direction='row' alignItems='center' mt={1} >
                                    <Typography fontSize={20} fontWeight='bold' noWrap >&#8377; {Number(data.p_price).toLocaleString('en-in')}&nbsp;&nbsp;</Typography>
                                    <Typography fontSize={16} sx={{textDecoration: 'line-through', color: '#848482'}} >&#8377; {Number(data.p_mrp).toLocaleString('en-in')}</Typography>
                                </Stack>
                                <Typography color='green' fontWeight='bold' >{ Number((Number(data.p_mrp) - (Number(data.p_price))) *100/Number(data.p_mrp)).toFixed(0) }% off</Typography>
                                <Typography fontSize={12} color='red' mt={1} >0% EMI available</Typography>
                            </Box>
                        </Stack>
                    </Grid>)}
                </Grid>
            </Box> }
            
            { speakers.length > 0 && 
            <Box mt={4} >
                <Toolbar style={{padding: 0}}>
                    <Typography sx={{flexGrow: 1}} fontSize={16} >Speakers</Typography>
                    <Button onClick={() => navi('/search-result/Speaker')} color="secondary" variant="outlined" size="small" >View all</Button>
                </Toolbar>
                <Grid container spacing={2}>
                    {speakers.map((data, i) =>
                    <Grid item xs={12} md={2.4} key={i} onClick={() => navi(`/description/${data.productid}`)} >
                        <Stack pb={1} pt={1} direction={{md: 'column', xs: 'row'}} sx={{border: '1.3px solid #BEBFC5', cursor: 'pointer'}} borderRadius={3} height={{md: 380, xs: 'auto'}} justifyContent='start' alignItems={{xs: 'center', md: 'start'}} >
                            <Box padding={3} sx={{width: {md: 'auto', xs: '30%'}}} >
                                <img src={`${product}/${data.pimg}`} alt={data.p_name} />
                            </Box>
                            <Box pl={1.5} pr={1.5} mb={1} sx={{width: {md: 'auto', xs: '70%'}}} >
                                <Typography fontSize={14} className="wrap-2" >{data.p_name}</Typography>
                                <Stack direction='row' alignItems='center' mt={1} >
                                    <Typography fontSize={20} fontWeight='bold' noWrap >&#8377; {Number(data.p_price).toLocaleString('en-in')}&nbsp;&nbsp;</Typography>
                                    <Typography fontSize={16} sx={{textDecoration: 'line-through', color: '#848482'}} >&#8377; {Number(data.p_mrp).toLocaleString('en-in')}</Typography>
                                </Stack>
                                <Typography color='green' fontWeight='bold' >{ Number((Number(data.p_mrp) - (Number(data.p_price))) *100/Number(data.p_mrp)).toFixed(0) }% off</Typography>
                                <Typography fontSize={12} color='red' mt={1} >0% EMI available</Typography>
                            </Box>
                        </Stack>
                    </Grid>)}
                </Grid>
            </Box> }
            
            { inverters.length > 0 &&
            <Box mt={4} >
                <Toolbar style={{padding: 0}}>
                    <Typography sx={{flexGrow: 1}} fontSize={16} >Inverters</Typography>
                    <Button onClick={() => navi('/search-result/Inverter')} color="secondary" variant="outlined" size="small" >View all</Button>
                </Toolbar>
                <Grid container spacing={2}>
                    {inverters.map((data, i) =>
                    <Grid item xs={12} md={2.4} key={i} onClick={() => navi(`/description/${data.productid}`)} >
                        <Stack pb={1} pt={1} direction={{md: 'column', xs: 'row'}} sx={{border: '1.3px solid #BEBFC5', cursor: 'pointer'}} borderRadius={3} height={{md: 380, xs: 'auto'}} justifyContent='start' alignItems={{xs: 'center', md: 'start'}} >
                            <Box padding={3} sx={{width: {md: 'auto', xs: '30%'}}} >
                                <img src={`${product}/${data.pimg}`} alt={data.p_name} />
                            </Box>
                            <Box pl={1.5} pr={1.5} mb={1} sx={{width: {md: 'auto', xs: '70%'}}} >
                                <Typography fontSize={14} className="wrap-2" >{data.p_name}</Typography>
                                <Stack direction='row' alignItems='center' mt={1} >
                                    <Typography fontSize={20} fontWeight='bold' noWrap >&#8377; {Number(data.p_price).toLocaleString('en-in')}&nbsp;&nbsp;</Typography>
                                    <Typography fontSize={16} sx={{textDecoration: 'line-through', color: '#848482'}} >&#8377; {Number(data.p_mrp).toLocaleString('en-in')}</Typography>
                                </Stack>
                                <Typography color='green' fontWeight='bold' >{ Number((Number(data.p_mrp) - (Number(data.p_price))) *100/Number(data.p_mrp)).toFixed(0) }% off</Typography>
                                <Typography fontSize={12} color='red' mt={1} >0% EMI available</Typography>
                            </Box>
                        </Stack>
                    </Grid>)}
                </Grid>
            </Box> }
            
            { tv.length > 0 &&
            <Box mt={4} >
                <Toolbar style={{padding: 0}}>
                    <Typography sx={{flexGrow: 1}} fontSize={16} >Televisions</Typography>
                    <Button onClick={() => navi('/search-result/TV')} color="secondary" variant="outlined" size="small" >View all</Button>
                </Toolbar>
                <Grid container spacing={2}>
                    {tv.map((data, i) =>
                    <Grid item xs={12} md={2.4} key={i} onClick={() => navi(`/description/${data.productid}`)} >
                        <Stack pb={1} pt={1} direction={{md: 'column', xs: 'row'}} sx={{border: '1.3px solid #BEBFC5', cursor: 'pointer'}} borderRadius={3} height={{md: 380, xs: 'auto'}} justifyContent='start' alignItems={{xs: 'center', md: 'start'}} >
                            <Box padding={3} sx={{width: {md: 'auto', xs: '30%'}}} >
                                <img src={`${product}/${data.pimg}`} alt={data.p_name} />
                            </Box>
                            <Box pl={1.5} pr={1.5} mb={1} sx={{width: {md: 'auto', xs: '70%'}}} >
                                <Typography fontSize={14} className="wrap-2" >{data.p_name}</Typography>
                                <Stack direction='row' alignItems='center' mt={1} >
                                    <Typography fontSize={20} fontWeight='bold' noWrap >&#8377; {Number(data.p_price).toLocaleString('en-in')}&nbsp;&nbsp;</Typography>
                                    <Typography fontSize={16} sx={{textDecoration: 'line-through', color: '#848482'}} >&#8377; {Number(data.p_mrp).toLocaleString('en-in')}</Typography>
                                </Stack>
                                <Typography color='green' fontWeight='bold' >{ Number((Number(data.p_mrp) - (Number(data.p_price))) *100/Number(data.p_mrp)).toFixed(0) }% off</Typography>
                                <Typography fontSize={12} color='red' mt={1} >0% EMI available</Typography>
                            </Box>
                        </Stack>
                    </Grid>)}
                </Grid>
            </Box> }
            
            { ac.length > 0 && 
            <Box mt={4} >
                <Toolbar style={{padding: 0}}>
                    <Typography sx={{flexGrow: 1}} fontSize={16} >Air Conditioners</Typography>
                    <Button onClick={() => navi('/search-result/Air Conditioner')} color="secondary" variant="outlined" size="small" >View all</Button>
                </Toolbar>
                <Grid container spacing={2}>
                    {ac.map((data, i) =>
                    <Grid item xs={12} md={2.4} key={i} onClick={() => navi(`/description/${data.productid}`)} >
                        <Stack pb={1} pt={1} direction={{md: 'column', xs: 'row'}} sx={{border: '1.3px solid #BEBFC5', cursor: 'pointer'}} borderRadius={3} height={{md: 380, xs: 'auto'}} justifyContent='start' alignItems={{xs: 'center', md: 'start'}} >
                            <Box padding={3} sx={{width: {md: 'auto', xs: '30%'}}} >
                                <img src={`${product}/${data.pimg}`} alt={data.p_name} />
                            </Box>
                            <Box pl={1.5} pr={1.5} mb={1} sx={{width: {md: 'auto', xs: '70%'}}} >
                                <Typography fontSize={14} className="wrap-2" >{data.p_name}</Typography>
                                <Stack direction='row' alignItems='center' mt={1} >
                                    <Typography fontSize={20} fontWeight='bold' noWrap >&#8377; {Number(data.p_price).toLocaleString('en-in')}&nbsp;&nbsp;</Typography>
                                    <Typography fontSize={16} sx={{textDecoration: 'line-through', color: '#848482'}} >&#8377; {Number(data.p_mrp).toLocaleString('en-in')}</Typography>
                                </Stack>
                                <Typography color='green' fontWeight='bold' >{ Number((Number(data.p_mrp) - (Number(data.p_price))) *100/Number(data.p_mrp)).toFixed(0) }% off</Typography>
                                <Typography fontSize={12} color='red' mt={1} >0% EMI available</Typography>
                            </Box>
                        </Stack>
                    </Grid>)}
                </Grid>
            </Box> }
            
            { chimney.length > 0 && 
            <Box mt={4} >
                <Toolbar style={{padding: 0}}>
                    <Typography sx={{flexGrow: 1}} fontSize={16} >Chimney</Typography>
                    <Button onClick={() => navi('/search-result/Chimney')} color="secondary" variant="outlined" size="small" >View all</Button>
                </Toolbar>
                <Grid container spacing={2}>
                    {chimney.map((data, i) =>
                    <Grid item xs={12} md={2.4} key={i} onClick={() => navi(`/description/${data.productid}`)} >
                        <Stack pb={1} pt={1} direction={{md: 'column', xs: 'row'}} sx={{border: '1.3px solid #BEBFC5', cursor: 'pointer'}} borderRadius={3} height={{md: 380, xs: 'auto'}} justifyContent='start' alignItems={{xs: 'center', md: 'start'}} >
                            <Box padding={3} sx={{width: {md: 'auto', xs: '30%'}}} >
                                <img src={`${product}/${data.pimg}`} alt={data.p_name} />
                            </Box>
                            <Box pl={1.5} pr={1.5} mb={1} sx={{width: {md: 'auto', xs: '70%'}}} >
                                <Typography fontSize={14} className="wrap-2" >{data.p_name}</Typography>
                                <Stack direction='row' alignItems='center' mt={1} >
                                    <Typography fontSize={20} fontWeight='bold' noWrap >&#8377; {Number(data.p_price).toLocaleString('en-in')}&nbsp;&nbsp;</Typography>
                                    <Typography fontSize={16} sx={{textDecoration: 'line-through', color: '#848482'}} >&#8377; {Number(data.p_mrp).toLocaleString('en-in')}</Typography>
                                </Stack>
                                <Typography color='green' fontWeight='bold' >{ Number((Number(data.p_mrp) - (Number(data.p_price))) *100/Number(data.p_mrp)).toFixed(0) }% off</Typography>
                                <Typography fontSize={12} color='red' mt={1} >0% EMI available</Typography>
                            </Box>
                        </Stack>
                    </Grid>)}
                </Grid>
            </Box> }
            
            { mixer.length > 0 && 
            <Box mt={4} >
                <Toolbar style={{padding: 0}}>
                    <Typography sx={{flexGrow: 1}} fontSize={16} >Mixer Grinder</Typography>
                    <Button onClick={() => navi('/search-result/Mixer Grinder')} color="secondary" variant="outlined" size="small" >View all</Button>
                </Toolbar>
                <Grid container spacing={2}>
                    {mixer.map((data, i) =>
                    <Grid item xs={12} md={2.4} key={i} onClick={() => navi(`/description/${data.productid}`)} >
                        <Stack pb={1} pt={1} direction={{md: 'column', xs: 'row'}} sx={{border: '1.3px solid #BEBFC5', cursor: 'pointer'}} borderRadius={3} height={{md: 380, xs: 'auto'}} justifyContent='start' alignItems={{xs: 'center', md: 'start'}} >
                            <Box padding={3} sx={{width: {md: 'auto', xs: '30%'}}} >
                                <img src={`${product}/${data.pimg}`} alt={data.p_name} />
                            </Box>
                            <Box pl={1.5} pr={1.5} mb={1} sx={{width: {md: 'auto', xs: '70%'}}} >
                                <Typography fontSize={14} className="wrap-2" >{data.p_name}</Typography>
                                <Stack direction='row' alignItems='center' mt={1} >
                                    <Typography fontSize={20} fontWeight='bold' noWrap >&#8377; {Number(data.p_price).toLocaleString('en-in')}&nbsp;&nbsp;</Typography>
                                    <Typography fontSize={16} sx={{textDecoration: 'line-through', color: '#848482'}} >&#8377; {Number(data.p_mrp).toLocaleString('en-in')}</Typography>
                                </Stack>
                                <Typography color='green' fontWeight='bold' >{ Number((Number(data.p_mrp) - (Number(data.p_price))) *100/Number(data.p_mrp)).toFixed(0) }% off</Typography>
                                <Typography fontSize={12} color='red' mt={1} >0% EMI available</Typography>
                            </Box>
                        </Stack>
                    </Grid>)}
                </Grid>
            </Box> }

        </Container>
        </>
    );
}