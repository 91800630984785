import React, { useEffect } from 'react'
import { } from 'react-router-dom'

// MUI
import { Card, CardContent, Container, Typography } from '@mui/material'

export default function Privacy(){
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return(
        <Container sx={{marginTop: '2em'}} >
            <Card>
                <CardContent>
                    <Typography variant='h4' mt={2} >PRIVACY POLICY</Typography>
                    <Typography variant='body1' mt={2} >
                    We value the trust you place in us and recognize the importance of secure transactions and information privacy. This Privacy Policy describes how E-World and its affiliates (collectively "E-world, we, our, us") collect, use, share, protect or otherwise process your personal information through E-world website https://www.eworldshop.in While you may be able to browse certain sections of the Platform without registering with us, however, please note we do not offer any product/service under this Platform outside India.
                    Your personal information will primarily be stored and processed in India and may have data protection laws that are different from those that apply in the country in which you are located. By visiting this Platform, providing your information or availing any product/service offered on the Platform, you expressly agree to be bound by the terms and conditions of this Privacy Policy, the Terms of Use and the applicable service/product terms and conditions, and agree to be governed by the laws of India including but not limited to the laws applicable to data protection and privacy. If you do not agree please do not use or access our Platform
                    </Typography>

                    <Typography variant='h4' mt={5} >Collection of Your Information</Typography>
                    <Typography variant='body1' color='black' mt={2}>
                    When you use our Platform, we collect and store your information which is provided by you from time to time. In general, you can browse the Platform without telling us who you are or revealing any personal information about yourself. 
                    We may collect personal information (such as email address, delivery address, name, phone number, credit card/debit card and other payment instrument details) from you when you set up an account or transact with us or participate in any event or contest. While you can browse some sections of our Platform without being a registered member, certain activities (such as placing an order or consuming our online content or services) do require registration. We use your contact information to send you offers based on your previous orders and your interests.
                    </Typography>

                    <Typography variant='h4' mt={5} >Security Precautions</Typography>
                    <Typography variant='body1' mt={2}>
                    We maintain reasonable physical, electronic and procedural safeguards to protect your information. Whenever you access your account information, we offer the use of a secure server. Once your information is in our possession, we adhere to our security guidelines to protect it against unauthorized access. However, by using the Platform, the users accept the inherent security implications of data transmission over the internet and the World Wide Web which cannot always be guaranteed as completely secure, and therefore, there would always remain certain inherent risks regarding use of the Platform. Users are responsible for ensuring the protection of login and password records for their account.
                    </Typography>

                    <Typography variant='h4' mt={5}>Your Rights</Typography>
                    <Typography variant='body1' mt={2}>
                    We take every reasonable step to ensure that your personal information that we process is accurate and, where necessary, kept up to date, and any of your personal information that we process that you inform us is inaccurate (having regard to the purposes for which they are processed) is erased or rectified. You may access, correct, and update your personal information directly through the functionalities provided on the Platform. You may delete certain non-mandatory information by logging into our website and visiting Profile and Settings sections. You can also write to us at the contact information provided below to assist you with these requests. You have an option to withdraw your consent that you have already provided by writing to us at the contact information provided below. Please mention “for withdrawal of consent” in the subject line of your communication. We will verify such requests before acting upon your request. Please note, however, that withdrawal of consent will not be retroactive and will be in accordance with the terms of this Privacy Policy, related Terms of Use and applicable laws. In the event you withdraw consent given to us under this Privacy Policy, such withdrawal may hamper your access to the Platform or restrict provision of our services to you for which we consider that information to be necessary.
                    </Typography>

                    <Typography variant='h4' mt={5}>Changes to this Privacy Policy</Typography>
                    <Typography variant='body1' mt={2} >
                    Please check our Privacy Policy periodically for changes. We may update this Privacy Policy to reflect changes to our information practices. We will alert you to significant changes by posting the date our Privacy Policy got last updated, placing a notice on our Platform, or by sending you an email when we are required to do so by applicable law.
                    </Typography>

                </CardContent>
            </Card>
        </Container>
    );
}