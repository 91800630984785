import React, { useEffect } from 'react'
import { } from 'react-router-dom'

// MUI
import { Card, CardContent, Container, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { Shortcut } from '@mui/icons-material'

export default function Shipping(){
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return(
        <Container sx={{marginTop: '2em'}} >
            <Card>
                <CardContent>
                    <Typography variant='h4' mt={2} >E-WORLD Shipping & Delivery Charges</Typography>
                    <Typography variant='body1' mt={2} color='gray' >
                    E-WORLD offers the lowest shipping cost across all online platforms. We have tied up with multiple Logistics Partners who pick up the product from your location and deliver it straight to the customer.
                    <br/>With such low delivery charges, you can receive orders from all over India and focus more on selling your products online than handling the stress of shipping and logistics all by yourself.
                    </Typography>
                </CardContent>
            </Card>
            <Card sx={{mt: 2}} >
                <CardContent>
                    <Typography variant='h4' mt={2} >e-WORLD Return Policy</Typography>
                    <Typography mt={2} variant='body1' color='gray' >At e-WORLD, we strive to ensure your complete satisfaction with every purchase. If, for any reason, you are not entirely pleased with your purchase, we have established a comprehensive return policy to make the process smooth and convenient for you.</Typography>

                    <Typography variant='body1' mt={2} fontWeight='bold' >Return Eligibility:</Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon><Shortcut /></ListItemIcon>
                            <ListItemText sx={{color: 'gray'}} >Products must be in their original condition, unused, and in the original packaging with all tags attached.</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><Shortcut /></ListItemIcon>
                            <ListItemText sx={{color: 'gray'}} >Electronic appliances and gadgets must be in working condition with no signs of damage or misuse.</ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon><Shortcut /></ListItemIcon>
                            <ListItemText sx={{color: 'gray'}} >Personalized or customized items cannot be returned unless they are defective or damaged upon arrival.</ListItemText>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
        </Container>
    );
}