import React, { useState } from "react";
import { NavLink, Outlet, useNavigate, useOutletContext } from 'react-router-dom'

// MUI
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Divider, Drawer, List, ListItem, ListItemText, Typography } from '@mui/material'
import { Home, People, Category, ShoppingCart, Person, ShoppingCartCheckout, Logout, Redeem } from '@mui/icons-material'

const links = [
    {name:'DASHBOARD', link:'dashboard', icon:<Home/>},
    {name:'CUSTOMERS', link:'customer', icon:<People/>},
    {name:'PRODUCTS', link:'product', icon:<Category/>},
    {name: 'ACTIVE ORDERS', link:'active-order', icon:<ShoppingCartCheckout/>},
    {name:'ORDERS', link:'order', icon:<ShoppingCart/>},
    {name:'PROFILE', link:'profile', icon:<Person/>}
]

export default function Sidebar({api}){
    const navi = useNavigate()
    const [logout, setLogout] = useState(false)
    const [open, setOpen] = useOutletContext()
    const [logging, setLogging] = useState(false)

    const logoutNow = () => {
        setLogging(true)
        var formData = new FormData()
        formData.append('logout', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLogging(false)
            if(data.success === true){
                // REDIRECT TO LOGIN PAGE
                navi('/login-admin', {replace: true})
            }
        })
    }

    return(
        <><Drawer sx={{display: {xs: 'none', md: 'block'}}} variant='permanent' >
            <List className="bgcolor" sx={{padding: 0, marginBottom: '5px'}} >
                <ListItem sx={{textAlign: 'center', padding: 0}} >
                    <ListItemText><img src="/logo.svg" alt="logo" style={{width: 92}} /></ListItemText>
                </ListItem>
            </List>
            { links.map((link, i) =>
            <NavLink to={link.link} key={i}>
                {({isActive}) => (
                    <Button startIcon={link.icon} color={isActive ? undefined : 'success'} className={isActive ? 'bgcolor' : undefined} variant={isActive ? 'contained' : 'text'} sx={{borderRadius: 0, boxShadow: 'none', padding: '1em', width: 240, justifyContent: 'flex-start'}} >{link.name}</Button>
                )}
            </NavLink>)}
            <Button color="error" startIcon={<Logout/>} sx={{borderRadius: 0, boxShadow: 'none', padding: '1em', width: 240, justifyContent: 'flex-start'}} onClick={() => setLogout(true)} >LOGOUT</Button>
        </Drawer>
        <Drawer sx={{display: {xs: 'block', md: 'none'}}} open={open} variant='temporary' onClose={() => setOpen(false)} >
            <List className="bgcolor" sx={{padding: 0, marginBottom: '5px'}} >
                <ListItem sx={{textAlign: 'center', padding: 0}} >
                    <ListItemText><img src="/logo.svg" alt="logo" style={{width: 92}} /></ListItemText>
                </ListItem>
            </List>
            { links.map((link, i) =>
            <NavLink to={link.link} key={i}>
                {({isActive}) => (
                    <Button onClick={() => setOpen(false)} startIcon={link.icon} color={isActive ? undefined : 'success'} className={isActive ? 'bgcolor' : undefined} variant={isActive ? 'contained' : 'text'} sx={{borderRadius: 0, boxShadow: 'none', padding: '1em', width: 240, justifyContent: 'flex-start'}} >{link.name}</Button>
                )}
            </NavLink>)}
            <Button color="error" startIcon={<Logout/>} sx={{borderRadius: 0, boxShadow: 'none', padding: '1em', width: 240, justifyContent: 'flex-start'}} onClick={() => setLogout(true)} >LOGOUT</Button>
        </Drawer><Outlet/>
        <Dialog open={logout} fullWidth={true} maxWidth='xs' onClose={() => setLogout(false)} >
            <DialogContent>
                <Typography variant="h4" >Do you want to logout?</Typography>
            </DialogContent><Divider/>
            <DialogActions>
                <Button color="inherit" onClick={() => setLogout(false)} >NO</Button>
                <Button color="error" disabled={logging} onClick={() => logoutNow()} >{logging ? <CircularProgress color="inherit" size={24} /> : 'YES'}</Button>
            </DialogActions>
        </Dialog>        
        </>
    );

}