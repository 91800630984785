import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from 'react-router-dom'

// MUI
import { AppBar, Avatar, IconButton, Toolbar, Typography } from '@mui/material'
import { } from '@mui/icons-material'

export default function HeaderAdmin({uiApi, api, asset}){
    const navi = useNavigate()
    useState(() => {
        fetch(uiApi).then(res => res.json())
        .then(data => {
            if(data.ui === false){
                navi('/login-admin', {replace: true})
            }
        })
    }, [uiApi])

    const [open, setOpen] = useState(false)

    const [profile, setProfile] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('profile', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setProfile(data)
            }
        })
    }, [api])

    return(
        <><AppBar sx={{boxShadow: 'none', flexGrow: 1}} position='sticky' className='bgcolor' component='nav' >
            <Toolbar>
                <img src="/logo.svg" alt="e-world logo" style={{width: 60}} />
                <Typography sx={{flexGrow: 1}} ></Typography>
                <Typography variant="body2" >Hi! {profile.name}</Typography>
                <IconButton onClick={() => setOpen(true)} ><Avatar src={asset+profile.avatar} /></IconButton>
            </Toolbar>
        </AppBar><Outlet context={[open, setOpen]} /></>
    );
}