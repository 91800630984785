import React, { useEffect, useState } from "react";
import {  } from 'react-router-dom'

// MUI
import { Avatar, Box, Container, Typography } from '@mui/material'
import { } from '@mui/icons-material'

export default function AdminProfile({api, asset}){

    const [profile, setProfile] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('profile', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setProfile(data)
            }
        })
    }, [api])

    return(
        <Box className="content">
            <Container>
                <Avatar src={asset+profile.avatar} style={{width: 200, height: 200, margin: 'auto', marginTop: 50}} />
                <Typography align="center" variant="h6" mt={2} color='gray' >Hello!</Typography>
                <Box sx={{width: {xs: '90%', md:'40%'} , background: '#fafafa', margin: 'auto', marginTop: 2, borderRadius: 1, boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px'}} >
                    <Typography align="center" variant="h4" p={2} >{profile.name}</Typography>
                </Box>
                <Typography align="center" variant="body1" mt={3} >You are logged in with</Typography>
                <Box className="bgcolor" sx={{width: {xs: '90%', md:'40%'} , margin: 'auto', marginTop: 1, borderRadius: 1, boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px'}} >
                    <Typography align="center" variant="h4" p={1} >{profile.mobile}</Typography>
                </Box>
            </Container>
        </Box>
    );
}