import React, { useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom'
import { useReactToPrint } from "react-to-print";
import html2pdf from 'html2pdf.js';

// MUI
import { AppBar, Box, Container, Grid, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Toolbar, Typography } from '@mui/material'
import { Download, Print, WhatsApp } from '@mui/icons-material'

export default function Invoice({api}){
    const { orderid } = useParams()

    const [invoice, setInvoice] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('invoice', orderid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setInvoice(data)
            }
        })
    }, [api])

    const area = useRef()
    const print = useReactToPrint({
        content:() => area.current
    })

    const handleDownload = () => {
        const content = area.current;
        html2pdf().from(content).save(orderid+'.pdf');
    };

    return(
        <Box>
            <AppBar position="sticky" >
                <Toolbar>
                    <Typography flexGrow={1} fontWeight='bold' >Invoice</Typography>

                    <IconButton color="inherit" onClick={() => print()} ><Print/></IconButton>
                    <IconButton color="inherit" onClick={() => handleDownload()} ><Download /></IconButton>
                    <a href={`https://wa.me/91${7667348043}/?text=${window.location.href}`} target="_blank" style={{color: 'white'}} ><IconButton color="inherit" ><WhatsApp /></IconButton></a>
                </Toolbar>
            </AppBar>

            <Container>
                <Grid item xs={12} md={8} overflow='auto' mb={4} mt={3} >
                    <Box sx={{width: '210mm', height: '296.5mm', position: 'relative', margin:'auto'}} ref={area} >
                        <Box sx={{backgroundColor: 'primary.main', width: '210mm', position: 'absolute', bottom: 0}}>
                            <Toolbar>
                                <Typography color='white' flex={1} >Call us: 91+ 6203625936</Typography>
                                <Typography color='white' >Website: www.eworldshop.in</Typography>
                            </Toolbar>
                        </Box>
                        <Box sx={{backgroundColor: 'primary.main'}} padding={2} pt={3} pb={3} >
                            <Toolbar>
                                <Box flexGrow={1} >
                                    <Typography color='white' fontFamily='serif' fontWeight='bold' fontSize={37} >e-WORLD</Typography>
                                </Box>

                                <Box textAlign='right' >
                                    <Typography fontWeight='bold' fontSize={22} color='black' >TAX-INVOICE</Typography>
                                    <Typography color='white' fontSize={10} >GSTIN - 10AHTPC7705N1Z6</Typography>
                                </Box>
                            </Toolbar>
                        </Box>
                        <Box padding={4} >
                            <Box >
                                <Stack direction='row' alignItems='center' >
                                    <Typography fontSize={14} letterSpacing={2} fontWeight='bold' fontFamily='serif' >BILLED TO</Typography>
                                    <Box sx={{backgroundColor: 'primary.main', width: '70mm', height: '0.5mm', ml: 1}} />
                                </Stack>

                                <Stack direction='row' justifyContent='space-between' alignItems='center' mt={2} >
                                    <Typography color='gray' variant="body2" >Name</Typography>
                                    <Typography fontWeight='bold' >{invoice.cf_name}</Typography>
                                </Stack>
                                <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1} >
                                    <Typography color='gray' variant="body2" >Address</Typography>
                                    <Typography fontWeight='bold' >{invoice.oa_address}</Typography>
                                </Stack>
                                <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1} >
                                    <Typography color='gray' variant="body2" >Mobile number</Typography>
                                    <Typography fontWeight='bold' >{invoice.c_mobile}</Typography>
                                </Stack>
                                <Stack direction='row' justifyContent='space-between' alignItems='center' mt={1} >
                                    <Typography color='gray' variant="body2" >Bill date</Typography>
                                    <Typography fontWeight='bold' >{invoice.o_date}</Typography>
                                </Stack>
                            </Box>

                            <Box mt={3}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>SL. NO.</TableCell>
                                            <TableCell>PRODUCT</TableCell>
                                            <TableCell align="right" >AMOUNT</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>1</TableCell>
                                            <TableCell>{invoice.o_name}</TableCell>
                                            <TableCell align="right" >{Number(invoice.o_price - (invoice.o_price*invoice.o_gst)/100).toLocaleString('en-in')} &#8377;</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell rowSpan={5} ></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell >CGST</TableCell>
                                            <TableCell align="right" >{invoice.o_gst/2}%</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>SGST</TableCell>
                                            <TableCell align="right" >{invoice.o_gst/2}%</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>TOTAL</TableCell>
                                            <TableCell align="right" >{Number(invoice.o_price).toLocaleString('en-in')} &#8377;</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>

                                <Typography mt={2} color='gray' letterSpacing={2} >Payment method: {invoice.o_payment_type}</Typography>
                            </Box>

                            <Box sx={{position: 'absolute', bottom: 100}} >
                                <Typography mb={2} variant="body2" color='gray' >*Thank you for your purchase. If you have any questions or concerns regarding this invoice, please contact us.</Typography>
                                <Stack direction='row' alignItems='center' >
                                    <Typography fontSize={30} letterSpacing={2} fontWeight='bold' fontFamily='serif' >e-WORLD</Typography>
                                    <Box sx={{backgroundColor: 'primary.main', width: '70mm', height: '0.5mm', ml: 1}} />
                                </Stack>
                                <Typography color='gray' variant="body2" >Address: Durgasthan 1no colony, Katihar</Typography>
                                <Typography color='gray' variant="body2" >State: Bihar</Typography>
                                <Typography color='gray' variant="body2" >City: Katihar</Typography>
                                <Typography color='gray' variant="body2" >Postal code: 854105</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Container>
        </Box>
    );
}