import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom'

// MUI
import { Alert, Avatar, Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogContent, Divider, Grid, Paper, Stack, TextField, Toolbar, Typography } from '@mui/material'
import { Bolt, Circle } from '@mui/icons-material'

export default function Checkout({api, product}){
    const { productid } = useParams()
    const [address, setAddress] = useState([])
    const [addWarn, setAddWarn] = useState('')

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    const navi = useNavigate()

    function cookie(cname){
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
        !cookie('true-customer') && navi('/login', {replace: true})
    }, [navi])

    useEffect(() => {
        var formData = new FormData()
        formData.append('get-address', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setAddWarn(data.warning)
            } else {
                setAddress(data)
            }
        })
    }, [api])

    const [productDetails, setProductDetails] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('checkout-product', productid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setProductDetails(data)
            }
        })
    }, [productid, api])

    const [working, setWorking] = useState(false)
    const [checkWarn, setCheckWarn] = useState('')
    const [orderSuccess, setOrderSuccess] = useState(false)
    const checkoutNow = () => {
        setCheckWarn(''); setWorking(true)
        var formData = new FormData()
        formData.append('checkout', productid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false)
            if(data.success){
                setOrderSuccess(true)
            } else {
                setCheckWarn(data.warning)
            }
        })
    }

    const [profile, setProfile] = useState([])
    useEffect(() => {
        const get_profile = localStorage.getItem('profile')
        setProfile(JSON.parse(get_profile))
    }, [])

    const [opencoinpay, setOpencoinpay] = useState(false)
    const [otpsent, setOtpsent] = useState(false)
    const [buywarn, setBuywarn] = useState('')
    const [buyotp, setBuyotp] = useState('')

    const send_otp = () => {
        setBuywarn(''); setWorking(true)
        var formData = new FormData()
        formData.append('initiate-coin-purchase', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false)
            if(data.warning){
                setBuywarn(data.warning)
            } else {
                setOtpsent(true)
            }
        })
    }

    const buy_product_using_coin = () => {
        setBuywarn(''); setWorking(true)
        var formData = new FormData()
        formData.append('buy-using-coin', productid)
        formData.append('otp', buyotp)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false)
            if(data.warning){
                setBuywarn(data.warning)
            } else {
                setOrderSuccess(true); opencoinpay(false)
            }
        })
    }

    const buy_product_using_gateway = () => {
        setBuywarn(''); setWorking(true)
        var formData = new FormData()
        formData.append('buy-using-gateway', productid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false)
            if(data.warning){
                setBuywarn(data.warning)
            } else {
                window.location.href = data.url
            }
        })
    }

    return(
        <Container>
            <Grid container component={Paper} mt={5} padding={3} mb={3} justifyContent='space-between' alignItems='center' >
                <Grid item xs={12} md={3}>
                    <Avatar src={product+productDetails.pimg} sx={{borderRadius: 0, width: 200, height: 200}} />
                </Grid>
                <Grid item xs={12} md={9} sx={{marginTop: {xs: '1.5em', md: 0}}} >
                    <Typography variant="h5" >{productDetails.p_name}</Typography>
                    <Typography color='gray' variant="body1" >Brand: {productDetails.p_brand}</Typography>
                    <Typography color='gray' variant="body1" >Color: {productDetails.p_color}</Typography>

                    <Stack direction='row' mt={3} >
                        <Typography variant="h5" >&#8377;{Number(productDetails.p_price).toLocaleString('en-in')} &nbsp;</Typography>
                        <Typography variant="h6" color='gray' sx={{textDecoration: 'line-through'}} >&#8377;{Number(productDetails.p_mrp).toLocaleString('en-in')}</Typography>
                        <Typography variant="h6" color='green'>&nbsp; &nbsp;{Number((Number(productDetails.p_mrp) - (Number(productDetails.p_price))) *100/Number(productDetails.p_mrp)).toFixed(0)}% off</Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Card >
                <CardContent>
                    { addWarn ? <Alert severity="warning">{addWarn}</Alert> :
                    <>
                    <Typography variant='h5' color='red' mb={2} >Shipping details</Typography>
                    <Typography variant="h5">{address.ca_name}</Typography>
                    <Typography variant='inherit' color='gray' >{address.ca_address}</Typography>
                    <Typography variant='inherit' color='gray' >{address.ca_city}</Typography>
                    <Typography variant='inherit' color='gray' >Postal: {address.ca_postal}</Typography>
                    <Typography variant='inherit' color='gray' >State: {address.ca_state}</Typography>
                    <Typography variant="inherit" color='gray' >Mobile No. : {address.ca_mobile}</Typography>
                    </>
                    }
                </CardContent>
            </Card>
            <Card sx={{marginTop: '1.5em'}}>
                { checkWarn && <Alert severity="warning">{checkWarn}</Alert> }
                { !addWarn &&
                <CardContent>
                    <Stack direction={{xs: 'column', md: 'row'}} justifyContent='center' spacing={1} >
                        <Button sx={{boxShadow: 0, padding: '1.2em 2.5em 1.2em 2.5em'}} variant='contained' color="primary" disabled={working} startIcon={working ? <CircularProgress color="inherit" size={24} /> : <Circle/>} onClick={() => setOpencoinpay(true)} >Buy using coin</Button>
                        <Button sx={{boxShadow: 0, padding: '1.2em 2.5em 1.2em 2.5em'}} variant='contained' color="error" disabled={working} startIcon={working ? <CircularProgress color="inherit" size={24} /> : <Bolt/>} onClick={() => buy_product_using_gateway()} >Order & Pay</Button>
                    </Stack>
                </CardContent>
                }
            </Card>

            <Dialog open={orderSuccess} fullWidth={true} maxWidth='md' PaperProps={{style: {backgroundColor: 'transparent', boxShadow: 'none'}}}>
                <Avatar src="/assets/success.gif" sx={{width: {xs: '100%', md: '50%'}, height: '100%', margin: 'auto', borderRadius: 10}} />
                <Typography align="center" mt={2} >
                    <Button variant="contained" color='inherit' onClick={() => navi('/orders')} >CHECK ORDERS</Button>
                </Typography>
            </Dialog>

            <Dialog open={opencoinpay} fullWidth={true} maxWidth='xs' onClose={() => setOpencoinpay(false)} >
                <DialogContent>
                    <Box>
                        <Toolbar>
                            <Typography flexGrow={1}>Your coins</Typography>
                            <Typography fontWeight='bold' >{profile.coins}</Typography>
                            <img src="/assets/coin.gif" alt="coin" style={{width: 25}} />
                        </Toolbar><Divider/>
                        <Toolbar>
                            <Typography flexGrow={1}>Need coins to buy</Typography>
                            <Typography fontWeight='bold' >{Math.round(productDetails.p_price/100)}</Typography>
                            <img src="/assets/coin.gif" alt="coin" style={{width: 25}} />
                        </Toolbar><Divider/>

                        { (parseInt(profile.coins)) >= 10 ?
                        (parseInt(profile.coins)) > (parseInt(productDetails.p_price)/100) ? 
                        <Box textAlign='center' mt={3} >
                            <Stack spacing={1.5} mb={2} >
                                { otpsent && 
                                <TextField fullWidth variant="outlined" label='Enter OTP' type="number" value={buyotp} onChange={(event) => setBuyotp(event.target.value)} /> }
                                {buywarn && <Alert severity="warning">{buywarn}</Alert> }
                            </Stack>
                            { otpsent === false ? 
                            <Button color="primary" size="large" variant="contained" onClick={() => send_otp()} disabled={working} >{working ? <CircularProgress color="inherit" size={24} /> : 'PROCEED'}</Button> :
                            <Button color="primary" size="large" variant="contained" onClick={() => buy_product_using_coin()} disabled={working} >{working ? <CircularProgress color="inherit" size={24} /> : 'CONFIRM'}</Button> }
                        </Box>
                        :
                        <Typography color='orange' fontWeight='bold' align="center" mt={3} >You need more coins to buy this product.</Typography>
                        :
                        <Typography color='orange' fontWeight='bold' align="center" >To buy any product you should have minimum 10 coins.</Typography>
                        }

                        <Box textAlign='center' mt={3}>
                            <Button color="error" onClick={() => setOpencoinpay(false)} >Cancel</Button>
                        </Box>

                    </Box>
                </DialogContent>
            </Dialog>

        </Container>
    );
}