import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'

// MUI
import { Alert, Box, Button, CircularProgress, Container, Dialog, DialogContent, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Snackbar, Stack, TextField, Typography } from '@mui/material'
import {  } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';

export default function CreateCustomer({api}){

    const navi = useNavigate()
    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const { referurl } = useParams()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [opensnack, setOpensnack] = useState(false)
    const [message, setMessage] = useState('')

    const [referby, setReferby ] = useState(referurl)

    const [sent, setSent] = useState(false)
    const [warning, setWarning] = useState('')
    const [working, setWorking] = useState(false)

    const [name, setName] = useState('')
    const [mobile, setMobile] = useState('')
    const [gender, setGender] = useState('')
    const [password, setPassword] = useState('')

    const send_otp = () => {
        setWarning('')
        if(name && mobile && gender && password !== ''){
            setWorking(true)
            var formData = new FormData()
            formData.append('verify-mobile-for-creation', mobile)
            formData.append('referid', referby)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setWarning(false); setWorking(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    // CODE GOES HERE
                    setSent(true)
                }
            })
        } else {
            setWarning('Please complete all field.')
        }
    }

    const [otpwarn, setOtpwarn] = useState('')
    const [otp, setOtp] = useState('')

    const create_account = () => {
        setWorking(true)
        var formData = new FormData()
        formData.append('create-account', mobile)
        formData.append('name', name)
        formData.append('gender', gender)
        formData.append('referby', referby)
        formData.append('otp', otp)
        formData.append('password', password)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWarning(false); setWorking(false)
            if(data.warning){
                setOtpwarn(data.warning)
            } else {
                navi('/')
            }
        })
    }

    return(
        <Box sx={{backgroundImage: "url('/assets/customer-login-bg.webp')"}} className="customer-login-bg" >
            <Snackbar open={opensnack} onClose={() => setOpensnack(false)} message={message} />
            <Container>
                <Grid container>
                    <Grid item xs={12} md={6} mx='auto' sx={{height: '100vh', width: '100%'}} display='flex' alignItems='center' >
                        <Box padding={2} pt={4} pb={4} sx={{width: '100%'}} borderRadius={4} className="glass shadow" >
                            <Typography mb={2} align="center" fontWeight='bold' fontSize={30} fontFamily='serif' letterSpacing={1} >e-WORLD</Typography><Divider/>

                            <Stack spacing={1.5} mt={2} >
                                <TextField fullWidth variant="standard" label='Name' type="text" value={name} onChange={(event) => setName(event.target.value)} />
                                <TextField fullWidth variant="standard" label='Mobile number' type="number" value={mobile} onChange={(event) => setMobile(event.target.value)} />
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel>Gender</InputLabel>
                                    <Select label='Gender' value={gender} onChange={(event) => setGender(event.target.value)} >
                                        <MenuItem value='Male' >Male</MenuItem>
                                        <MenuItem value='Female' >Female</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField fullWidth variant="standard" label='Create password' type="text" value={password} onChange={(event) => setPassword(event.target.value)} />
                                <TextField fullWidth variant="standard" label='Refer by (Optional)' type="text" disabled={referurl === '0' ? false : true} value={referby === '0' ? '' : referby} onChange={(event) => setReferby(event.target.value)} />

                                {warning && <Alert severity="warning">{warning}</Alert> }

                                <Box textAlign='center'>
                                    <Button variant="contained" size="large" onClick={() => send_otp()} disabled={working} >{working ? <CircularProgress color="inherit" size={24} /> : 'Create account'}</Button>
                                </Box>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Dialog open={sent} fullWidth={true} maxWidth='xs'>
                <DialogContent>
                    <Box pt={3} pb={3} >
                        <Stack spacing={1.5} >
                            <TextField fullWidth variant="outlined" label='OTP' value={otp} onChange={(event) => setOtp(event.target.value)} />
                            {otpwarn && <Alert severity="warning">{otpwarn}</Alert> }
                        </Stack>
                        <Box textAlign='center' mt={3} >
                            <Button variant="contained" size="large" disabled={working} onClick={() => create_account()} >{working ? <CircularProgress color="inherit" size={24} /> : 'Verify OTP'}</Button>
                        </Box>
                        <Typography mt={2} color='green' variant='subtitle2' align="right" className="pointer" onClick={() => setSent(false)} >Already have an account, login here</Typography>
                    </Box>
                </DialogContent>
            </Dialog>
        </Box>
    );
}