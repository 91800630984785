import React, { useState } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom'

// MUI
import { Alert, Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Stack, TextField, Typography } from '@mui/material'
import { } from '@mui/icons-material'

export default function AdminLogin({loginApi, uiApi}){
    const navi = useNavigate()

    useState(() => {
        fetch(uiApi).then(res => res.json())
        .then(data => {
            if(data.ui === true){
                navi('/admin/dashboard', {replace: true})
            }
        })
    }, [uiApi])

    const [mobile, setMobile] = useState('')
    const [password, setPassword] = useState('')
    const [warning, setWarning] = useState('')
    const [loading, setLoading] = useState(false)
    const login = () => {
        setLoading(true)
        var formData = new FormData()
        formData.append('login', mobile)
        formData.append('password', password)
        fetch(loginApi, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else if(data.success){
                // REDIRECT HERE
                navi('/admin/dashboard', {replace: true})
            } else {
                setWarning('Something is wrong.')
            }
        })
    }

    return(
        <Box sx={{backgroundImage: "url('/assets/login.jpg')", backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}} >
            <Container>
                <Grid container justifyContent='center' alignItems='center' sx={{height: '100vh'}} >
                    <Grid item xs={12} md={6} >
                        <Card elevation={10} className='blur' >
                            <CardContent>
                                <Typography align="center" variant="h4" mt={3} sx={{letterSpacing: '5px', fontWeight: 'bold'}} >e-WORLD ADMIN</Typography>
                                <Stack spacing={5} mt={2} mb={1} >
                                    <TextField fullWidth variant="standard" label='Mobile number*' type='number' value={mobile} onChange={(event) => setMobile(event.target.value)} />
                                    <TextField fullWidth variant="standard" label='Password*' type='password' value={password} onChange={(event) => setPassword(event.target.value)} />
                                </Stack>
                                {warning && <Alert severity="warning" >{warning}</Alert> }
                                <Typography align="center" mt={3} >
                                    <Button variant="contained" color="inherit" size="large" mt={3} onClick={() => login()} disabled={loading} >{loading ? <CircularProgress color="inherit" size={24} /> : 'LOGIN'}</Button>
                                </Typography>
                                <Typography align="right" mt={3}>
                                    <Button color="inherit" onClick={() => navi('reset')} >RESET PASSWORD</Button>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Routes>
                <Route path="reset" element={<Reset />} />
            </Routes>
        </Box>
    );

    function Reset(){

        const [sent, setSent] = useState(false)

        const [mobile, setMobile] = useState('')
        const [password, setPassword] =  useState('')
        const [otp, setOtp] = useState('')
        const [processing, setProcessing] = useState(false)
        const [warning, setWarning] = useState('')

        const verify = () => {
            setWarning(''); setProcessing(true)
            var formData = new FormData()
            formData.append('verify-mobile', mobile)
            fetch(loginApi, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setProcessing(false)
                if(data.warning){
                    setWarning(data.warning)
                } if(data.success === true){
                    setSent(true)
                }
            })
        }

        const reset = () => {
            setWarning(''); setProcessing(true)
            var formData = new FormData()
            formData.append('reset-password', mobile)
            formData.append('password', password)
            formData.append('otp', otp)
            fetch(loginApi, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setProcessing(false)
                if(data.warning){
                    setWarning(data.warning)
                } else if(data.success === true){
                    // REDIRECT HERE
                    navi('/admin/dashboard', {replace: true})
                }
            })
        }

        return(
            <Dialog open={true} fullWidth>
                <DialogTitle>Reset password</DialogTitle><Divider/>
                <DialogContent>
                    <Stack spacing={3} mb={1} >
                        <TextField fullWidth variant="standard" label='Verify mobile number' type='number' disabled={sent} value={mobile} onChange={(event) => setMobile(event.target.value)} />
                        {sent === true && <>
                        <TextField fullWidth variant="standard" label='Create password' type='text' value={password} onChange={(event) => setPassword(event.target.value)} />
                        <TextField fullWidth variant="standard" label='OTP' type='number' value={otp} placeholder='OTP sent to your mobile number.' onChange={(event) => setOtp(event.target.value)} /></>}
                    </Stack>
                    {warning && <Alert severity="warning">{warning}</Alert> }
                </DialogContent><Divider/>
                <DialogActions>
                    <Button color="inherit" onClick={() => navi(-1)} >CLOSE</Button>
                    { sent === true ? 
                    <Button color="success" onClick={() => reset()} disabled={processing} >{processing ? <CircularProgress color="inherit" size={24} /> : 'RESET'}</Button> :
                    <Button color="error" onClick={() => verify()} disabled={processing} >{processing ? <CircularProgress color="inherit" size={24} /> : 'VERIFY'}</Button> }
                </DialogActions>
            </Dialog>
        );
    }

}