import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'

// MUI
import { Alert, Box, Container, FormControl, IconButton, Input, InputAdornment, InputLabel, LinearProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Receipt, Search } from '@mui/icons-material'

export default function AdminOrder({api}){
    const navi = useNavigate()

    const [orders, setOrders] = useState([])
    const [warning, setWarning] = useState('')
    const [loading, setLoading] = useState(true)

    const [searchQuery, setSearchQuery] = useState('')
    const search = () => {
        setWarning('')
        var formData = new FormData()
        formData.append('orderHistory', searchQuery)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setOrders(data)
            }
        })
    }

    useEffect(() => {
        search()
    }, [])

    const keyUpSearch = (event) => {
        setSearchQuery(event.target.value)
        if(event.keyCode === 13){
            search()
        }
    }

    const iconSearch = () => {
        search()
    }
    
    return(
        <Box className="content">
            <Container>
                <Stack spacing={2} >
                    <FormControl variant="standard" fullWidth >
                        <InputLabel htmlFor="Search orders" >Search orders</InputLabel>
                        <Input aria-label="Search orders" placeholder="Search by customer name, mobile" onKeyUp={(event) => keyUpSearch(event)} endAdornment={<InputAdornment position="end" ><IconButton disabled={searchQuery === '' ? true : false} onClick={() => iconSearch()} ><Search/></IconButton></InputAdornment>} />
                    </FormControl>

                    { loading ? <LinearProgress color="success" /> :
                    warning ? <Alert severity="warning">{warning}</Alert> :
                    <TableContainer component={Paper} elevation={0} sx={{maxHeight: 550}} >
                        <Table stickyHeader >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" >#</TableCell>
                                    <TableCell align="left" >CUSTOMER</TableCell>
                                    <TableCell align="left" >PRODUCT</TableCell>
                                    <TableCell align="left" >PRICE</TableCell>
                                    <TableCell align="left" >DILIVERED</TableCell>
                                    <TableCell align="left" >Invoice</TableCell>
                                </TableRow>
                            </TableHead>
                            { orders.map((order, i) =>
                            <TableBody key={i} >
                                <TableRow>
                                    <TableCell align="left" >{ orders.length - i }</TableCell>
                                    <TableCell align="left" >{order.cf_name}</TableCell>
                                    <TableCell align="left" >{order.o_name}</TableCell>
                                    <TableCell align="left" >&#8377;{Number(order.o_price).toLocaleString('en-in')}</TableCell>
                                    <TableCell align="left" >{order.o_date}</TableCell>
                                    <TableCell align="left"><IconButton color='warning' onClick={() => navi(`/invoice/${order.orderid}`)} ><Receipt/></IconButton></TableCell>
                                </TableRow>
                            </TableBody>) }
                        </Table>
                    </TableContainer>}
                </Stack>

            </Container>
        </Box>
    );
}