import React, { useEffect } from 'react'
import { } from 'react-router-dom'

// MUI
import { Card, CardContent, Container, Typography } from '@mui/material'

export default function Terms(){
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return(
        <Container sx={{marginTop: '2em'}} >
            <Card>
                <CardContent>
                    <Typography variant='h4' mt={2} >Terms and conditions</Typography>
                    <Typography variant='body1' mt={2} >
                    Please read these terms and conditions ("Terms") carefully before accessing or using the Platform (defined hereinafter). These Terms along with the Privacy Policy published on the Platform ("Privacy Policy") and other policies (as may be notified/displayed/published on the Platform) constitutes the contract between the Users of this Platform and Company (collectively "Agreement"). By use of the Platform, Users agree to be bound by these Agreement as posted on the Platform from time to time.
                    </Typography>

                    <Typography variant='h4' mt={2} >User Account, Password, and Security:</Typography>
                    <Typography variant='body1' mt={2}>
                    If You use the Platform, you shall be responsible for maintaining the confidentiality of your Display Name and Password and You shall be responsible for all activities that occur under your Display Name and Password. You agree that if You provide any information that is untrue, inaccurate, not current or incomplete, we shall have the right to indefinitely suspend or terminate or block access of your membership on the Platform.
                    You agree to
                    •	immediately notify E-WORLD of any unauthorized use / breach of your password or account and
                    •	ensure that you exit from your account at the end of each session.
                    </Typography>

                    <Typography variant='h4' mt={2}>User Conduct and Rules on the Platform:</Typography>
                    <Typography variant='body1' mt={2}>
                    You agree, undertake and confirm that Your use of the Platform shall be strictly governed by the following binding principles:
                    1.	belongs to another person and to which You do not have any right to
                    2.	is misleading in any way
                    3.	contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page)
                    4.	contains video, photographs, or images of another person (with a minor or an adult).
                    5.	interferes with another USER's use and enjoyment of the Platform or any other individual's User and enjoyment of similar services
                    6.	violates any law for the time being in force
                    7.	shall not create liability for Us or cause Us to lose (in whole or in part) the services of Our internet service provider ("ISPs") or other suppliers
                    8.	Users doesn't reply to the payment verification mail sent by E-WORLD
                    9.	Users fails to produce adequate documents during the payment details verification
                    10.	Misuse of another User’s phone/email
                    11.	Users uses invalid address, email and phone no.
                    12.	Users returns the wrong product
                    13.	Users refuses to pay for an order
                    14.	Users involved in the snatch and run of any order
                    15.	Miscellaneous activities conducted with the sole intention to cause loss to business/revenue to E-WORLD
                    16.	User with excessive returns
                    17.	Repeated request for monetary compensation for fake/used order
                    </Typography>

                    <Typography variant='h4' mt={2}>e-WORLD's Replacement Guarantee</Typography>
                    <Typography variant='body1' mt={2}>
                    e-WORLD's Replacement Guarantee seeks to assist Buyers who have been defrauded by qualified sellers on the Platform. The return policy period (E-WORLD 's Replacement Guarantee) depends on the product category and the seller. Kindly click here to know the return policy period applicable for different categories. If at the time of delivery and/or within the applicable return policy period, if any defect is found, then the buyer of the product/s can ask for replacement of the product/s from the seller subject to the following terms and conditions
                    1.	Notify seller of any defects in the product/s at the time of delivery of the product/s and/or within the applicable return policy period and the same product/s will be replaced in return of the defective product/s.
                    2.	Replacement can be for the entire product/s or part/s of the product subject to availability of the same with the seller.
                    Following products shall not be eligible for return or replacement:
                    a. Damages due to misuse of product;
                    b. Incidental damage due to malfunctioning of product;
                    c. Products with tampered or missing serial/UPC numbers;
                    d. Any damage/defect which are not covered under the manufacturer's warranty
                    e. Any product that is returned without all original packaging and accessories, including the box, manufacturer's packaging if any, and all other items originally included with the product/s delivered.
                    </Typography>

                    <Typography variant='h4' mt={2}>Product Description</Typography>
                    <Typography variant='body1' mt={2}>
                    E-WORLD we do not warrant that Product description or other content of this Platform is accurate, complete, reliable, current, or error-free and assumes no liability in this regard.
                    Limitation of Liability
                    IN NO EVENT SHALL E-WORLD BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH THESE TERMS OF USE, EVEN IF USER HAS BEEN INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES.
                    </Typography>

                </CardContent>
            </Card>
        </Container>
    );
}