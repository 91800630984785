import React, { useEffect, useState } from "react";
import { } from 'react-router-dom'

// MUI
import { Alert, Box, Button, CircularProgress, Container, Dialog, DialogContent, FormControl, IconButton, Input, InputAdornment, InputLabel, LinearProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Redeem, Search } from '@mui/icons-material'

export default function AdminRedeem({api}){

    const [searchQuery, setSearchQuery] = useState('')
    useEffect(() => {
        redeemCustomers(api)
    }, [api])

    const [customers, setCustomers] = useState([])
    const [loading, setLoading] = useState(true)
    const [warning, setWarning] = useState('')
    const redeemCustomers = (api) => {
        var formData = new FormData(); setWarning('')
        formData.append('redeem-customers', searchQuery)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setCustomers(data)
            }
        })
    }

    const searchByKey = (event) => {
        setSearchQuery(event.target.value)
        if(event.keyCode === 13){
            redeemCustomers(api)
        }
    }

    const [redeemBox, setRedeemBox] = useState(false)
    const [redeemCustomerid, setRedeemCustomerid] = useState('')
    const [redeemWarn, setRedeemWarn] = useState('')
    const [working, setWorking] = useState(false)
    const [coins, setCoins] = useState('')

    const redeem = () => {
        setWorking(true)
        var formData = new FormData()
        formData.append('redeem-redeem-coins', redeemCustomerid)
        formData.append('coins', coins)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false); setRedeemWarn('')
            if(data.warning){
                setRedeemWarn(data.warning)
            } else if(data.success){
                redeemCustomers(api)
                setRedeemBox(false)
            }
        })
    }

    return(
        <><Box className="content" >
        <Container>
            <Stack spacing={2}>
                <FormControl variant="standard" fullWidth >
                    <InputLabel htmlFor="searchCustomer" >Search customer</InputLabel>
                    <Input placeholder="Search by customer name, mobile..." id="searchCustomer" type="text" onKeyUp={(event) => searchByKey(event)} endAdornment={<InputAdornment position="end" ><IconButton onClick={() => redeemCustomers(searchQuery, api)} ><Search/></IconButton></InputAdornment>}/>
                </FormControl>

                <TableContainer component={Paper} elevation={0} sx={{maxHeight: 500}} >
                    { loading ? <LinearProgress color="success" /> :
                    warning ? <Alert severity="warning">{warning}</Alert> :
                    <Table stickyHeader >
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" >SR. NO.</TableCell>
                                <TableCell align="left" >CUSTOMER</TableCell>
                                <TableCell align="left" >REFER ID</TableCell>
                                <TableCell align="left" >COINS</TableCell>
                                <TableCell align="left" >REDEEM</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { customers.map((data, i) =>
                            <TableRow key={i} >
                                <TableCell align="left" >{i+1}</TableCell>
                                <TableCell align="left" >{data.cf_name} {data.cl_name}</TableCell>
                                <TableCell align="left" >{data.c_referid}</TableCell>
                                <TableCell align="left" >{Number(data.coins) - Number(data.redeemed)}</TableCell>
                                <TableCell align="left" ><IconButton onClick={() => setRedeemBox(true, setRedeemCustomerid(data.customerid))} ><Redeem/></IconButton></TableCell>
                            </TableRow>) }
                        </TableBody>
                    </Table>}
                </TableContainer>
            </Stack>
        </Container>
        </Box>
        <Dialog open={redeemBox} fullWidth={true} maxWidth='xs' onClose={() => setRedeemBox(false)} >
            <DialogContent>
                <Stack spacing={2}>
                    <TextField fullWidth variant="standard" label='Enter coins to redeem' type='number' autoFocus onChange={(event) => setCoins(event.target.value)} />
                    {redeemWarn && <Alert severity="warning">{redeemWarn}</Alert>}
                    <Typography align="center" >
                        <Button variant="contained" sx={{boxShadow: 'none'}} color='error' onClick={() => redeem()} disabled={working} >{working ? <CircularProgress color="inherit" size={24} /> : 'REDEEM'}</Button>
                    </Typography>
                </Stack>
            </DialogContent>
        </Dialog>
        </>
    );

}