import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'

// MUI
import { Alert, Avatar, Box, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, LinearProgress, OutlinedInput, Paper, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material'
import { Add, Edit, Search, ShoppingBag, Visibility } from '@mui/icons-material'

import ConfigureCustomer from './configure'
import AddressCustomer from './address'
import OrderCustomer from './order'

export default function AdminCustomer({product, api}){
    const navi = useNavigate()

    const [customers, setCustomers] = useState([])
    const [warning, setWarning] = useState('')
    const [loading, setLoading] = useState(true)

    const [searchQuery, setSearchQuery] = useState('')

    useEffect(() => {
        search()
    }, [])

    const search = () => {
        setWarning('')
        var formData = new FormData()
        formData.append('customers', searchQuery)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setCustomers(data)
            }
        })
    }

    const keyUpSearch = (event) => {
        setSearchQuery(event.target.value)
        if(event.keyCode === 13){
            search()
        }
    }

    const response = (event) => {
        event === 'success' && search()
        navi(-1)
    }

    return(
        <><Box className="content">
            <Container>
                <FormControl variant="standard" fullWidth >
                    <InputLabel htmlFor="searchCustomer" >Search customer</InputLabel>
                    <Input placeholder="Search by customer name, mobile..." id="searchCustomer" type="text" onKeyUp={(event) => keyUpSearch(event)} endAdornment={<InputAdornment position="end" ><IconButton onClick={() => search()} ><Search/></IconButton></InputAdornment>}/>
                </FormControl>
                
                <Typography align="center" mb={1} mt={1}>
                    <Button color="success" startIcon={<Add/>} onClick={() => navi('0')} >ADD CUSTOMER</Button>
                </Typography>

                <TableContainer component={Paper} elevation={0} sx={{maxHeight: 500}}>
                    { loading ? <LinearProgress color="success" /> :
                    warning ? <Alert severity="warning">{warning}</Alert> :
                    <Table stickyHeader >
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">#</TableCell>
                                <TableCell align="left" >NAME</TableCell>
                                <TableCell align="left">REFER ID</TableCell>
                                <TableCell align="left" >MOBILE</TableCell>
                                <TableCell align="left" >GENDER</TableCell>
                                <TableCell align="left">SELL</TableCell>
                                <TableCell align="left" >COINS</TableCell>
                                <TableCell align="left" >REFERRED</TableCell>
                                <TableCell align="left" >ORDERS</TableCell>
                                <TableCell align="left" >ADDRESS</TableCell>
                                <TableCell align="left" >A/C</TableCell>
                                <TableCell align="left" >EDIT</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { customers.map((cust, i) =>
                            <TableRow key={i} sx={{background: cust.c_status === 'Pending' && '#fff59d'}} >
                                <TableCell align="left">{customers.length - i}</TableCell>
                                <TableCell align="left" >{cust.cf_name}</TableCell>
                                <TableCell align="left">{cust.c_referid}</TableCell>
                                <TableCell align="left" >{cust.c_mobile}</TableCell>
                                <TableCell align="left" >{cust.c_gender}</TableCell>
                                <TableCell><IconButton onClick={() => navi(`get-id/${cust.customerid}`) } ><ShoppingBag/></IconButton></TableCell>
                                <TableCell align="left" ><IconButton onClick={() => navi(`coin/${cust.customerid}`)} ><Visibility/></IconButton></TableCell>
                                <TableCell align="left" ><IconButton onClick={() => navi(`refer/${cust.c_referid}`)} ><Visibility/></IconButton></TableCell>
                                <TableCell align="left"><IconButton onClick={() => navi(`order/${cust.customerid}`)} ><Visibility/></IconButton></TableCell>
                                <TableCell align="left" ><IconButton onClick={() => navi(`address/${cust.customerid}/${cust.addressid}`)} ><Visibility/></IconButton></TableCell>
                                <TableCell align="left" ><IconButton onClick={() => navi(`ac/${cust.customerid}`)} ><Visibility/></IconButton></TableCell>
                                <TableCell align="left" ><IconButton onClick={() => navi(cust.customerid)} ><Edit/></IconButton></TableCell>
                            </TableRow>) }
                        </TableBody>
                    </Table>}
                </TableContainer>
            </Container>
        </Box>
        <Routes>
            <Route path="order/:customerid" element={<OrderCustomer api={api} product={product} />} />
            <Route path="address/:customerid/:addressid" element={<AddressCustomer api={api} response={(event) => response(event)} />} />
            <Route path=":customerid" element={<ConfigureCustomer api={api} response={(event) => response(event)} />} />
            <Route path="get-id/:customerid" element={<GetProductKey/>} />
            <Route path="sell/:customerid/:productid" element={<Sell/>} />
            <Route path="coin/:customerid" element={<Coins/>} />
            <Route path="refer/:referid" element={<Refer/>} />
            <Route path="ac/:customerid" element={<Account/>} />
        </Routes> </>
    );

    function GetProductKey(){
        const { customerid } = useParams()
        const [productid, setProductid] = useState('')
        const getid = (event) => {
            setProductid(event.target.value)
            if(productid !== ''){
                if(event.keyCode === 13){
                    navi(`sell/${customerid}/${productid}`)
                }
            }
        }

        const iconClickGo = () => {
            navi(`sell/${customerid}/${productid}`)
        }

        return(
            <Dialog open={true} fullWidth={true} maxWidth='xs' onClose={() => navi(-1)}>
                <DialogContent>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="search" >Enter product ID</InputLabel>
                        <OutlinedInput id="search" label='Enter product ID' autoFocus type="text" onKeyUp={(event) => getid(event)} endAdornment={<InputAdornment position="end" ><IconButton disabled={productid === '' && true} onClick={() => iconClickGo()} ><Search/></IconButton></InputAdornment>} />
                    </FormControl>
                </DialogContent>
            </Dialog>
        );
    }

    function Sell(){

        const { customerid } = useParams()
        const { productid } = useParams()

        const [warning, setWarning] = useState('')
        const [pro, setProduct] = useState([])

        useEffect(() => {
            setWarning('')
            var formData = new FormData()
            formData.append('search-product-for-sell', productid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    setProduct(data)
                }
            })
        }, [productid])

        const [cust, setCust] = useState([])
        useEffect(() => {
            var formData = new FormData()
            formData.append('customer-details', customerid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setCust(data)
                }
            })
        }, [customerid])

        const [custAdd, setCustAdd] = useState([])
        const [sellAddWarn, setSellAddWarn] = useState('')
        useEffect(() => {
            var formData = new FormData()
            formData.append('customer-shipping-address', customerid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(data.warning){
                    setSellAddWarn(data.warning)
                } else {
                    setCustAdd(data)
                }
            })
        }, [customerid])

        const [sellWarn, setWarn] = useState('')
        const [selling, setSelling] = useState(false)
        const sell = () => {
            setSelling(true); setWarn('')
            var formData = new FormData()
            formData.append('sell-product', productid)
            formData.append('customerid', customerid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setSelling(false)
                if(data.success){
                    setSoldDialog(true)
                } else {
                    setWarn(data.warning)
                }
            })
        }

        const [soldDialog, setSoldDialog] = useState(false)
        const [sellAlert, setSellAlert] = useState(false)

        return(
            <><Dialog open={true} fullWidth={true} maxWidth='md' onClose={() => navi(-1)} >
                <DialogTitle>Sell product</DialogTitle><Divider/>
                <DialogContent>
                { warning ? <Alert severity="warning">{warning}</Alert> :
                <Box>
                    <Grid container sx={{background: '#f5f5f5', borderRadius: '3px'}} p={1} mb={1} >
                        <Grid item xs={12} md={3}>
                            <Avatar src={product+pro.pimg} sx={{width: 160, height: 'auto', borderRadius: 0}} />
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Typography variant="h5" mt={2} >{pro.p_name}</Typography>
                            <Typography color='gray' >Brand: {pro.p_brand}</Typography>
                            <Typography color='gray' >Color: {pro.p_color}</Typography>
                            <Toolbar style={{padding: 0}} >
                                <Typography variant="h5" noWrap >&#8377;{Number(pro.p_price).toLocaleString('en-in')} &nbsp;</Typography>
                                <Typography sx={{textDecoration: 'line-through'}} color='gray' noWrap >&#8377;{Number(pro.p_mrp).toLocaleString('en-in')}</Typography>
                                <Typography color='green' variant="h6" noWrap >&nbsp; { Number((Number(pro.p_mrp) - (Number(pro.p_price))) *100/Number(pro.p_mrp)).toFixed(0) }% off</Typography>
                            </Toolbar>
                        </Grid>
                    </Grid>
                    <Card elevation={0} sx={{background: '#f5f5f5', marginBottom: '8px'}} >
                        <CardContent>
                            <Typography color='gray' >Customer details</Typography>
                            <Typography variant="h5" mt={1} >{cust.cf_name} {cust.cl_name}</Typography>
                            <Typography color='gray' variant="body2" >Mobile: {cust.c_mobile}</Typography>
                        </CardContent>
                    </Card>
                    <Card elevation={0} sx={{background: '#f5f5f5', marginBottom: '7px'}} >
                        { sellAddWarn ? <Alert severity="warning">Please add customer shipping address.</Alert> :
                        <CardContent>
                            <Typography color='gray' >Shipping details</Typography>
                            <Typography variant="h5" mt={1} >{custAdd.ca_name}</Typography>
                            <Typography color='gray' variant="body2" >{custAdd.ca_address}</Typography>
                            <Typography color='gray' variant="body2" >State: {custAdd.ca_state}</Typography>
                            <Typography color='gray' variant="body2" >City: {custAdd.ca_city}</Typography>
                            <Typography color='gray' variant="body2" >Postal: {custAdd.ca_postal}</Typography>
                            <Typography color='gray' variant="body2" >Mobile: {custAdd.ca_mobile}</Typography>
                        </CardContent>}
                    </Card>
                </Box>
                }
                </DialogContent><Divider/>
                <DialogActions sx={{textAlign: 'center'}} >
                    <Button variant="text" color="inherit" onClick={() => navi(-1)} >CLOSE</Button>
                    { sellAddWarn === '' &&
                    <Button variant="contained" color="success" onClick={() => setSellAlert(true)} >SELL NOW</Button>}
                </DialogActions>
            </Dialog>

            <Dialog open={sellAlert} fullWidth={true} maxWidth='xs'>
                <DialogContent >
                    <Typography mt={4} variant="h4" align="center" >Did you verified the sell?</Typography>
                    <Typography color='gray' variant="body2" align="center" mt={1} mb={1} >Note: You can't undone this process. But you can change the status of this selling.</Typography>

                    {sellWarn && <Alert severity="warning" >{sellWarn}</Alert>}

                    <Stack direction='row' justifyContent='center' mt={3} mb={4} spacing={1} >
                        <Button color="error" onClick={() => setSellAlert(false)} >DON'T SELL</Button>
                        <Button color="success" variant="contained" disabled={selling} onClick={() => sell()} >{selling ? <CircularProgress color="inherit" size={24} /> : 'YES, SELL'}</Button>
                    </Stack>
                </DialogContent>
            </Dialog>

            <Dialog open={soldDialog} fullWidth={true} maxWidth='xs' PaperProps={{style: {borderRadius: 40}}} >
                <DialogContent>
                    <Box pl={5} pr={5} >
                        <img src='/assets/success.gif' alt='Success sell GIF' />
                    </Box>
                    <Typography align="center" color='green' variant='h6' >Selling of produrct is succeed!</Typography>
                    <Typography align="center" mt={2} mb={5} >
                        <Button variant="contained" color='error' sx={{boxShadow: 'none', borderRadius: 0, width: '40%', padding: '0.8em'}} onClick={() => navi(-2)} >OK</Button>
                    </Typography>
                </DialogContent>
            </Dialog></>
        );
    }

    function Coins(){
        const { customerid } = useParams()

        const [refer, setRefer] = useState([])
        const [warning, setWarning] = useState('')
        const [loading, setLoading] = useState(true)
        
        useEffect(() => {
            var formData = new FormData()
            formData.append('customer-coins', customerid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setLoading(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    setRefer(data)
                }
            })
        }, [customerid])

        const [coins, setCoins] = useState([])
        useEffect(() => {
            var formData = new FormData()
            formData.append('customer-coins-sum', customerid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setCoins(data)
                }
            })
        }, [customerid])

        return(
            <Dialog open={true} fullWidth={true} onClose={() => navi(-1)}>
                <DialogContent>
                    { loading ? <LinearProgress color="success" /> :
                    warning ? <Alert severity="warning">{warning}</Alert> :
                    <>
                    <Stack spacing={2} direction='row' justifyContent='space-between' sx={{backgroundColor: '#8d99ae', borderRadius: 1}} padding={2} >
                        <Typography align='center' color='white' >Coins - {Number(coins.coins)}</Typography>
                        <Typography align='center' color='white'>Redeemed - {Number(coins.redeemed)}</Typography>
                        <Typography align='center' color='white'>Available - {Number(coins.coins) - Number(coins.redeemed)}</Typography>
                    </Stack>
                    <TableContainer sx={{maxHeight: '400px'}} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">SR.NO.</TableCell>
                                    <TableCell align="left" >DATE</TableCell>
                                    <TableCell align="left" >FROM</TableCell>
                                    <TableCell align="left" >COIN</TableCell>
                                    <TableCell align="left">STATUS</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { refer.map((re, i) =>
                                <TableRow key={i} sx={{backgroundColor: re.coin_status === 'Redeemed' && '#dee2e6'}} >
                                    <TableCell align="left">{refer.length - i}</TableCell>
                                    <TableCell align="left" >{re.coin_date}</TableCell>
                                    <TableCell align="left" >{re.coin_from }</TableCell>
                                    <TableCell align="left" >{re.coin}</TableCell>
                                    <TableCell align="left" >{re.coin_status}</TableCell>
                                </TableRow>) }
                            </TableBody>
                        </Table>
                    </TableContainer></>}
                </DialogContent>
            </Dialog>
        );
    }

    function Refer(){

        const { referid } = useParams()

        const [referred, setReferred] = useState([])
        const [warning, setWarning] = useState('')
        const [loading, setLoading] = useState(true)

        useEffect(() => {
            setWarning('')
            var formData = new FormData()
            formData.append('referred', referid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setLoading(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    setReferred(data)
                }
            })
        }, [referid])

        return(
            <Dialog open={true} fullWidth={true} maxWidth='xs' onClose={() => navi(-1)}>
                <DialogContent>
                    <Typography variant="h6" mb={2} align='center' >Referred to</Typography><Divider/>
                    { loading ? <LinearProgress color="success" /> :
                    warning ? <Alert severity="warning" style={{marginTop: '5px'}} >{warning}</Alert> :
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" >NAME</TableCell>
                                    <TableCell align="right" >REFERRED ON</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { referred.map((re, i) =>
                                <TableRow key={i} >
                                    <TableCell align="left" >{re.cf_name} {re.cl_name}</TableCell>
                                    <TableCell align="right" >{re.c_arrive}</TableCell>
                                </TableRow>) }
                            </TableBody>
                        </Table>
                    </TableContainer>}
                </DialogContent>
            </Dialog>
        );
    }

    function Account(){
        const { customerid } = useParams()
        const [open, setOpen] = useState(false)
        const [message, setMessage] = useState('')

        const [ac, setAc] = useState('')
        const [ifsc, setIfsc] = useState('')
        const [upi, setUpi] = useState('')

        useEffect(() => {
            var formData = new FormData()
            formData.append('customer-details', customerid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setAc(data.c_ac); setIfsc(data.c_ifsc); setUpi(data.c_upi)
                }
            })
        }, [customerid])

        const [working, setWorking] = useState(false)
        const update_ac = () => {
            setWorking(true)
            var formData = new FormData()
            formData.append('update-customer-account-detail', customerid)
            formData.append('account-number', ac)
            formData.append('ifsc', ifsc)
            formData.append('upi', upi)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setWorking(false)
                if(data.warning){
                    setMessage(data.warning); setOpen(true)
                } else {
                    navi(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth maxWidth='xs' onClose={() => navi(-1)}>
                <Snackbar open={open} message={message} onClose={() => setOpen(false)} />
                <DialogTitle>Bank account detail</DialogTitle><Divider/>
                <DialogContent>
                    <Box>
                        <Stack spacing={1.5} >
                            <TextField fullWidth variant="outlined" label='Account number' type="number" value={ac} onChange={(event) => setAc(event.target.value)} />
                            <TextField fullWidth variant="outlined" label='IFSC' type="text" value={ifsc} onChange={(event) => setIfsc(event.target.value)} />
                            <TextField fullWidth variant="outlined" label='UPI ID' type="text" value={upi} onChange={(event) => setUpi(event.target.value)} />
                        </Stack>
                    </Box>
                </DialogContent><Divider/>
                <DialogActions>
                    <Button color="inherit" onClick={() => navi(-1)} >Close</Button>
                    <Button color="primary" onClick={() => update_ac()} disabled={working} >{working ? <CircularProgress color="inherit" size={24} /> : 'Update'}</Button>
                </DialogActions>
            </Dialog>
        );
    }

}