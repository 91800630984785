import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'

// MUI
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';

export default function ConfigureCustomer({api, response}){
    let navi = useNavigate()
    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));

    const { customerid } = useParams()

    const [name, setName] = useState('')
    const [mobile, setMobile] = useState('')
    const [gender, setGender] = useState('')
    const [arrive, setArrive] = useState('')
    const [referby, setReferby] = useState('')
    const [status, setStatus] = useState('')
    const [uploading, setUploadig] = useState(false)
    const [warning, setWarning] = useState('')

    useEffect(() => {
        var formData = new FormData()
        formData.append('customer-details', customerid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setName(data.cf_name); setMobile(data.c_mobile); setGender(data.c_gender); setArrive(data.c_arrive); setReferby(data.c_referby); setStatus(data.c_status)
            }
        })
    }, [customerid, api])

    const upload = () => {
        setWarning(''); setUploadig(true)
        var formData = new FormData()
        formData.append('configure-customer', customerid)
        formData.append('name', name)
        formData.append('mobile', mobile)
        formData.append('gender', gender)
        formData.append('referby', referby);
        formData.append('arrive', arrive)
        formData.append('status', status)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setUploadig(false)
            if(data.warning){
                setWarning(data.warning)
            } else if(data.success){
                response('success')
            }
        })
    }

    const [opendel, setOpendel] = useState(false)
    const [deletewarn, setDeletewarn] = useState('')
    const [deleting, setDeleting] = useState(false)
    const [password, setPassword] = useState('')
    const delete_customer = () => {
        setDeletewarn(''); setDeleting(true)
        var formData = new FormData()
        formData.append('delete-customer', customerid)
        formData.append('password', password)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setDeleting(false)
            if(data.warning){
                setDeletewarn(data.warning)
            } else {
                response('success')
            }
        })
    }

    return(
        <Dialog open={true} onClose={() => navi(-1)} fullWidth={true} maxWidth='xs' fullScreen={fullScreen} >
            <DialogTitle>Configure</DialogTitle><Divider/>
            <DialogContent>
                <Stack spacing={2} mb={1} >
                    <TextField fullWidth variant="standard" type='text' label='Full name' value={name} onChange={(event) => setName(event.target.value)} />
                    <TextField fullWidth variant="standard" type='number' helperText='This number will use for Login, OTP' label='Mobile number' value={mobile} onChange={(event) => setMobile(event.target.value)} />
                    <FormControl fullWidth variant="standard" >
                        <InputLabel>Gender</InputLabel>
                        <Select value={gender} onChange={(event) => setGender(event.target.value)} >
                            <MenuItem value='Male' >Male</MenuItem>
                            <MenuItem value='Female' >Female</MenuItem>
                            <MenuItem value='Others' >Others</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField fullWidth variant="standard" type='date' helperText='Creation date' value={arrive} onChange={(event) => setArrive(event.target.value)} />
                    <TextField fullWidth variant="standard" type='text' label='Referred by' value={referby} onChange={(event) => setReferby(event.target.value)} />
                    <FormControl fullWidth variant="standard">
                        <InputLabel>Status</InputLabel>
                        <Select value={status} onChange={(event) => setStatus(event.target.value)} >
                            <MenuItem value='Active' >Active</MenuItem>
                            <MenuItem value='Pending' >Pending</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                { warning && <Alert severity="warning">{warning}</Alert> }
            </DialogContent><Divider/>
            <DialogActions>
                <Button color="error" variant="text" onClick={() => setOpendel(true)} >Delete</Button>
                <Button onClick={() => navi(-1)} color='inherit' >Close</Button>
                <Button color="success" onClick={() => upload()} disabled={uploading} >{uploading ? <CircularProgress color="inherit" size={24} /> : 'Upload'}</Button>
            </DialogActions>

            <Dialog open={opendel} fullWidth maxWidth='xs' onClose={() => setOpendel(false)} >
                <DialogContent>
                    <Box pt={2} pb={2} >
                        <Typography color='red' fontWeight='bold' fontSize={23} >Delete this customer</Typography>
                        <Typography color='orange' fontSize={14} fontWeight='bold' mb={3} >Remember, you can't undo this process.</Typography>
                        <Typography color='gray' fontSize={12} >To delete this customer you have to enter your login password.</Typography>
                        <Stack spacing={1.5}>
                            <TextField fullWidth label='Login password' variant="standard" size="small" value={password} onChange={(event) => setPassword(event.target.value)} />
                            {deletewarn && <Alert severity="warning">{deletewarn}</Alert> }
                        </Stack>

                        <Box textAlign='center' mt={2} >
                            <Button variant="contained" color="error" disabled={deleting} onClick={() => delete_customer()} >{deleting ? <CircularProgress color="inherit" size={24} /> : 'Confirm & Delete'}</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
      </Dialog>
    );
}