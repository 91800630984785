import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'

import Recommendation from "./recommendation";

// MUI
import { Alert, Box, Container, Grid, LinearProgress, Stack, Typography } from '@mui/material'

export default function SearchResult({api, product}){
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    const navi = useNavigate()
    const { query } = useParams()
    
    const [results, setResults] = useState([])
    const [warning, setWarning] = useState('')
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        setWarning('')
        var formData = new FormData()
        formData.append('search-product', query)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setResults(data)
            }
        })
    }, [api, query])

    return(
        <Container>
            <Typography variant="body1" mt={5} mb={2} >Search result for "{query}"</Typography>

            { loading ? <LinearProgress color="success" /> :
            warning ? <Alert severity="warning">{warning}</Alert> :
            <Box mt={1} >
                <Grid container spacing={2}>
                    {results.map((data, i) =>
                    <Grid item xs={12} md={2.4} key={i} onClick={() => navi(`/description/${data.productid}`)} >
                        <Stack pb={1} pt={1} direction={{md: 'column', xs: 'row'}} sx={{border: '1.3px solid #BEBFC5', cursor: 'pointer'}} borderRadius={3} height={{md: 380, xs: 'auto'}} justifyContent='start' alignItems={{xs: 'center', md: 'start'}} >
                            <Box padding={3} sx={{width: {md: 'auto', xs: '30%'}}} >
                                <img src={product+data.pimg} alt={data.p_name} />
                            </Box>
                            <Box pl={1.5} pr={1.5} mb={1} sx={{width: {md: 'auto', xs: '70%'}}} >
                                <Typography fontSize={14} className="wrap-2" >{data.p_name}</Typography>
                                <Stack direction='row' alignItems='center' mt={1} >
                                    <Typography fontSize={20} fontWeight='bold' noWrap >&#8377; {Number(data.p_price).toLocaleString('en-in')}&nbsp;&nbsp;</Typography>
                                    <Typography fontSize={16} sx={{textDecoration: 'line-through', color: '#848482'}} >&#8377; {Number(data.p_mrp).toLocaleString('en-in')}</Typography>
                                </Stack>
                                <Typography color='green' fontWeight='bold' >{ Number((Number(data.p_mrp) - (Number(data.p_price))) *100/Number(data.p_mrp)).toFixed(0) }% off</Typography>
                                <Typography fontSize={12} color='red' mt={1} >0% EMI available</Typography>
                            </Box>
                        </Stack>
                    </Grid>)}
                </Grid>
            </Box> }
            
            <Typography fontSize={16} mt={4} >Products you may like</Typography>
            <Recommendation product={product} api={api} category='' />
        </Container>
    );
}