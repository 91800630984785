import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom'

import Bar from "../../bar";

// MUI
import { Accordion, AccordionDetails, AccordionSummary, Alert, Avatar, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, LinearProgress, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Paper, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material'
import { Add, ExpandMore, History, Launch, Redeem, Share } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';
import axios from "axios";

export default function UserProfile({api}){
    //const { profile } = useOutletContext()

    const [profile, setProfile] = useState([])
    const [action, setAction] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0)
        const getProfile = localStorage.getItem("profile")
        setProfile(JSON.parse(getProfile))

        const get_level = localStorage.getItem('customer-level')
        get_level !== null && setLevel(JSON.parse(get_level))

        const get_referred = localStorage.getItem('customer-referred')
        get_referred !== null && setReferred(JSON.parse(get_referred))
    }, [])

    const [opensnack, setOpensnack] = useState(false)
    const [message, setMessage] = useState('')
    const navi = useNavigate()
    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));

    const [referred, setReferred] = useState([])
    const [referwarn, setReferwarn] = useState('')
    const [referloading, setReferloading] = useState(true)
    useEffect(() => {
        setReferwarn('')
        var formData = new FormData()
        formData.append('referred-by-me', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setReferloading(false)
            if(data.warning){
                setReferwarn(data.warning)
            } else {
                localStorage.setItem('customer-referred', JSON.stringify(data))
                const get_referred = localStorage.getItem('customer-referred')
                get_referred !== null && setReferred(JSON.parse(get_referred))
            }
        })
    }, [])

    const [level, setLevel] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('referred-level', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setReferwarn(data.warning)
            } else {
                localStorage.setItem('customer-level', JSON.stringify(data))
                const get_level = localStorage.getItem('customer-level')
                get_level !== null && setLevel(JSON.parse(get_level))
            }
        })
    }, [])

    const [openaddmoney, setOpenaddmoney] = useState(false)
    const [coins, setCoins] = useState('')
    const [working, setWorking] = useState(false)
    const add_coin = () => {
        setWorking(true)
        var formData = new FormData()
        formData.append('initiate-payment', coins)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false)
            if(data.warning){
                setMessage(data.warning); setOpensnack(true)
            } else {
                if(data.status === 'false'){
                    setMessage(data.warning); setOpensnack(true)
                } else if(data.status === true){
                    window.location.href = data.url
                }
            }
        })
    }

    const activate_account = () => {
        setAction('payment-initiate');
        var formData = new FormData()
        formData.append('initiate-phonepe-payment', 'activate_account')
        formData.append('amount', 590)
        axios.post(api, formData)
        .then(function(res){
            setAction('')
            if(res.data.success === true){
                window.location.href = res.data.data.instrumentResponse.redirectInfo.url
            }
        })

    }

    const referlink = "https://wa.me/?text=Hi! This is my refer ID - https://eworldshop.in/create-customer/"+profile.c_referid+" Kindly use it to create your account. I will be thankful to you."

    return(
        <><Snackbar open={opensnack} message={message} anchorOrigin={{vertical: 'top', horizontal: 'center'}} onClose={() => setOpensnack(false)} />
        <Container>
            <Grid container mt={1} spacing={2} >
                <Grid item xs={12} md={4.5}>
                    <Box padding={2} component={Paper} height={160} >
                        <Box textAlign='center' >
                            <Avatar sx={{margin: 'auto', width: 120, height: 120}} src={`/assets/${profile.c_gender === 'Male' ? 'male.svg' : 'female.svg'}`} />

                            <Typography color='black' fontSize={20} fontWeight='bold' mt={1} letterSpacing={1} noWrap >Hey, {profile.cf_name}</Typography>
                        </Box>
                    </Box>
                    <Box padding={2} component={Paper} mt={1} height={{xs: 'auto', md: 175}} >
                        <Toolbar>
                            <Typography sx={{flexGrow: 1}} color='gray' >Coins <IconButton size="small" color="warning" onClick={() => navi('coin-history')} ><History /></IconButton> </Typography>
                            <img src="/assets/coin.gif" alt="coin" style={{width: 30}} /> <Typography fontWeight='bold' letterSpacing={1} >{profile.coins}</Typography>
                        </Toolbar><Divider/>
                        <Toolbar>
                            <Typography sx={{flexGrow: 1}} color='gray' >Coins Value</Typography>
                            <Typography fontWeight='bold' >&#8377; {Number(parseInt(profile.coins)*100).toLocaleString('en-in')}</Typography>
                        </Toolbar><Divider/>

                        <Stack direction='row' mt={1} spacing={1} >
                            <Button variant="contained" color="warning" sx={{borderRadius: 0, boxShadow: 0, width: '100%'}} startIcon={<Redeem/>} onClick={() => navi('redeem-coin')} >REDEEM</Button>
                            <Button variant="contained" color="primary" sx={{borderRadius: 0, boxShadow: 0, width: '100%'}} startIcon={<Add/>} onClick={() => navi('add-coin')} >ADD COIN</Button>
                        </Stack>
                    </Box>
                    <Box padding={2} component={Paper} mt={1} height={220} >
                        <Toolbar>
                            <Typography flexGrow={1} color='gray' >Refer ID</Typography>
                            <a target="_blank" href={referlink}><IconButton color="primary" ><Share/></IconButton></a>
                            <Typography letterSpacing={1} >{profile.c_referid}</Typography>
                        </Toolbar><Divider/>
                        <Toolbar>
                            <Typography flexGrow={1} color='gray' >Mobile</Typography>
                            <Typography>+91 {profile.c_mobile}</Typography>
                        </Toolbar><Divider/>

                        <Box mt={1} >
                        <a target="_blank" href={referlink}><Button variant="contained" color="primary" sx={{borderRadius: 0, boxShadow: 0, width: '100%'}} startIcon={<Share/>} >SHARE</Button></a>
                        </Box>

                        <Stack mt={1} direction='row' spacing={1} >
                            <Button sx={{width: '100%'}} variant="outlined" onClick={() => navi('address')} >ADDRESS</Button>
                            <Button sx={{width: '100%'}} variant="outlined" onClick={() => navi('/orders')} >ORDERS</Button>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item xs={12} md={7.5}>
                    <Box padding={2} component={Paper} height={{xs: 'auto', md: 295}} overflow='auto' >
                        <Typography fontWeight='bold' mb={1} >Referred Level</Typography> <Divider/>

                        <List>
                            <ListItem>
                                <ListItemText>Level 1</ListItemText>
                                <ListItemIcon>{level.level1}</ListItemIcon>
                            </ListItem><Divider/>
                            <ListItem>
                                <ListItemText>Level 2</ListItemText>
                                <ListItemIcon>{level.level2}</ListItemIcon>
                            </ListItem><Divider/>
                            <ListItem>
                                <ListItemText>Level 3</ListItemText>
                                <ListItemIcon>{level.level3}</ListItemIcon>
                            </ListItem><Divider/>
                            <ListItem>
                                <ListItemText>Level 4</ListItemText>
                                <ListItemIcon>{level.level4}</ListItemIcon>
                            </ListItem><Divider/>
                            <ListItem>
                                <ListItemText>Level 5</ListItemText>
                                <ListItemIcon>{level.level5}</ListItemIcon>
                            </ListItem>
                        </List>
                    </Box>

                    <Box padding={2} mt={1} component={Paper} height={{xs: 'auto', md: 300}} overflow='auto' >
                        <Typography fontWeight='bold' mb={1} >Referred by you</Typography> <Divider/>
                        { referloading ? <LinearProgress color="primary" /> :
                        referwarn ? <Alert severity="warning">{referwarn}</Alert> :
                        <List>
                            { referred.map((data, i) =>
                            <ListItem alignItems="flex-start" key={i} >
                                <ListItemAvatar>
                                    <Avatar alt="Referred people" src={`/assets/${data.c_gender === 'Male' ? 'male.svg' : 'female.svg'}`} />
                                </ListItemAvatar>
                                <ListItemText primary={data.fname} secondary={`Referred on: ${data.referdate}`} />
                            </ListItem>) }
                        </List> }

                    </Box>
                </Grid>
            </Grid>
            <Dialog open={profile.c_status === 'Pending' ? true : false } fullWidth maxWidth='xs' PaperProps={{style:{ borderRadius: 25 }}} >
                <DialogContent>
                    <Box pt={3} pb={3} >
                        <Box textAlign='center'>
                            <img src="/assets/sad.gif" alt="Sad emoji" style={{width: 150}} />
                        </Box>
                        <Box>
                            <Typography fontWeight='bold' color='red' align="center" fontSize={35} letterSpacing={3} >Oops!</Typography>
                            <Typography fontWeight='bold' color='orange' align="center" fontSize={18} >Your account is pending!</Typography>
                        </Box>
                        <Box textAlign='center' mt={2} mb={2} >
                            <Button variant="contained" size="large" onClick={() => activate_account()} disabled={action === 'payment-initiate' ? true : false} endIcon={action === 'payment-initiate' ? <CircularProgress color="inherit" size={24} /> : <Launch size={24} />} >Activate Now - 590 &#8377;</Button>
                        </Box><Divider/>
                        <Box mt={1} >
                            <Typography align="center" >Key points</Typography>
                            <Typography color='gray' variant="body2" >1. To activate your account you have to pay 590 &#8377; (Include GST).</Typography>
                            <Typography color='gray' variant="body2" >2. You will get all refund by coin face.</Typography>
                            <Typography color='gray' variant="body2" >3. You can redeem this coin or can buy products.</Typography>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <Routes>
                <Route path='coin-history' element={<Coin_history />} />
                <Route path='add-coin' element={<Add_coin />} />
                <Route path='redeem-coin' element={<Redeem_coin />} />
                <Route path='address' element={<Address />} />
            </Routes>
        </Container></>
    );

    function Coin_history(){

        const [coins, setCoins] = useState([])
        const [warning, setWarning] = useState('')
        const [loading, setLoading] = useState(true)
        useEffect(() => {
            setWarning('')
            var formData = new FormData()
            formData.append('get-coin-history', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setLoading(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    setCoins(data)
                }
            })
        }, [])

        return(
            <Dialog open={true} fullWidth={true} maxWidth='xs' fullScreen={fullScreen} onClose={() => navi(-1)} >
                <Bar text='Coin history' />
                <DialogContent>
                    { loading ? <LinearProgress color="primary" /> :
                    warning ? <Alert severity="warning">{warning}</Alert> :
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell align="center" >Coins</TableCell>
                                    <TableCell align="right" >Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { coins.map((data, i) =>
                                <TableRow key={i} >
                                    <TableCell>{data.coin_date}</TableCell>
                                    <TableCell align="center" >{data.coin}</TableCell>
                                    <TableCell align="right" >{data.coin_status === 'Redeemed' ?  <Typography variant="body2" color='red' >Redeemed</Typography> : data.coin_status === 'Active' ? <Typography variant="body2" color='green' >Received</Typography> : data.coin_status === 'Request' && <Typography variant="body2" color='orange' >Request</Typography> }</TableCell>
                                </TableRow>) }
                            </TableBody>
                        </Table>
                    </TableContainer>}
                </DialogContent>
            </Dialog>
        );
    }

    function Add_coin(){

        const [coins, setCoins] = useState('')
        const [warning, setWarning] = useState('')
        const [working, setWorking] = useState(false)

        const add_coin = () => {
            setWarning(''); setWorking(true)
            var formData = new FormData()
            formData.append('add-coin', coins)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setWorking(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    if(data.status === 'false'){
                        setWarning(data.msg)
                    } else if(data.status === true){
                        window.location.href = data.url
                    }
                }
            })
        }

        return(
            <Dialog open={true} fullWidth={true} maxWidth='xs' onClose={() => navi(-1)}>
                <DialogContent>
                    <Stack spacing={2} mt={4} >
                        <TextField fullWidth label='Enter coins' value={coins} onChange={(event) => setCoins(event.target.value)} />
                        { warning && <Alert severity="warning">{warning}</Alert> }
                    </Stack>
                    <Box textAlign='center' mt={2} mb={2} >
                        <Button variant="contained" color="primary" sx={{boxShadow: 0, borderRadius: 0}} disabled={working} onClick={() => add_coin()} >{working ? <CircularProgress color="inherit" size={24} /> : 'ADD COIN'}</Button>
                    </Box>
                    <Box mt={1} borderRadius={2} sx={{background: '#f8f9fa'}} padding={2} >
                        <Typography color='gray' fontSize={12} >Every coin is the value of 100 rupees (1 Coin = 100 Rupees).</Typography>
                        <Typography color='gray' fontSize={12} >This process will redirect you to the payment page.</Typography>
                        <Typography color='gray' fontSize={12} >If you face any problem while this process, you can contact us.</Typography>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    }

    function Redeem_coin(){

        const [coins, setCoins] = useState(10)
        const [otp, setOtp] = useState('')
        const [warning, setWarning] = useState('')
        const [working, setWorking] = useState(false)

        const [ac, setAc] = useState('')
        const [ifsc, setIfsc] = useState('')
        const [upi, setUpi] = useState('')
        useEffect(() => {
            var formData = new FormData()
            formData.append('get-account-detail', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setAc(data.c_ac); setIfsc(data.c_ifsc); setUpi(data.c_upi)
                }
            })
        }, [])

        const [acwarn, setAcwarn] = useState('')
        const [acupdating, setAcupdating] = useState(false)
        const update_ac = () => {
            setAcwarn(''); setAcupdating(true)
            var formData = new FormData()
            formData.append('update-account-detail', ac)
            formData.append('ifsc', ifsc)
            formData.append('upi', upi)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setAcupdating(false)
                if(data.warning){
                    setAcwarn(data.warning)
                } else {
                    setAcwarn('Bank account detail updation succeed.')
                }
            })
        }

        const [otpsent, setOtpsent] = useState(false)
        const process_redeem_coin = () => {
            setWarning(''); setWorking(true)
            var formData = new FormData()
            formData.append('redeem-coin-process', coins)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setWorking(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    setOtpsent(true)
                }
            })
        }

        const redeem_coin = () => {
            setWarning(''); setWorking(true)
            var formData = new FormData()
            formData.append('redeem-coin', coins)
            formData.append('otp', otp)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setWorking(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    setRequestsent(true)
                }
            })
        }

        const [requestsent, setRequestsent] = useState(false)        

        return(
            <Dialog open={true} fullWidth={true} maxWidth='xs' onClose={() => navi(-1)}>
                <DialogTitle>Redeem coins</DialogTitle><Divider/>
                <DialogContent>
                    <Stack spacing={1.5} mb={1} >
                        <TextField fullWidth label='Enter coins' value={coins} onChange={(event) => setCoins(event.target.value)} />
                        {otpsent === true && <TextField fullWidth label='Enter OTP' value={otp} onChange={(event) => setOtp(event.target.value)} /> }
                        { warning && <Alert severity="warning">{warning}</Alert> }
                    </Stack>

                    <Accordion sx={{borderRadius: 1}} >
                        <AccordionSummary
                        expandIcon={<ExpandMore />}
                        >
                        <Typography>Account Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={1.5} >
                                <TextField fullWidth type="number" variant="outlined" label='Account number' value={ac} onChange={(event) => setAc(event.target.value)} />
                                <TextField fullWidth type="text" variant="outlined" label='IFSC' value={ifsc} onChange={(event) => setIfsc(event.target.value)} />
                                <TextField fullWidth type="text" variant="outlined" label='UPI ID' value={upi} onChange={(event) => setUpi(event.target.value)} />
                                { acwarn && <Alert severity="warning">{acwarn}</Alert> }
                                <Box textAlign='center' >
                                    <Button color="primary" variant="outlined" disabled={acupdating} onClick={() => update_ac()} >{acupdating ? <CircularProgress color="inherit" size={24} /> : 'UPDATE'}</Button>
                                </Box>
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                    <Box mb={2} mt={2} >
                        <Typography variant="body2" color='gray'>Note:</Typography>
                        <Typography fontSize={12} color='gray' >1. 1 coin = 100 Rupees</Typography>
                        <Typography fontSize={12} color='gray' >2. Settlement of your coin will take 1 business day.</Typography>
                    </Box>
                </DialogContent><Divider/>
                <DialogActions>
                    <Button color="inherit" variant="text" onClick={() => navi(-1)} >CLOSE</Button>
                    {otpsent === false && <Button variant="contained" color="warning" disabled={working} onClick={() => process_redeem_coin()} >{working ? <CircularProgress color="inherit" size={24} /> : 'REDEEM COIN'}</Button> }
                    {otpsent === true && <Button variant="contained" color="warning" disabled={working} onClick={() => redeem_coin()} >{working ? <CircularProgress color="inherit" size={24} /> : 'REDEEM NOW'}</Button> }
                </DialogActions>

                <Dialog open={requestsent} fullWidth={true} maxWidth='xs' >
                    <DialogContent>
                        <Box textAlign='center' pt={2} pb={2} >
                            <Typography fontWeight='bold' fontSize={24} >Your request was sent.</Typography>
                            <Typography color='gray' variant="caption" >This process will take 1 business day.</Typography>
                            
                            <Box textAlign='center' mt={2} >
                                <Button variant="contained" color="primary" onClick={() => navi(-1)} sx={{boxShadow: 0}} >Go Back</Button>
                            </Box>

                        </Box>
                    </DialogContent>
                </Dialog>

            </Dialog>
        );
    }

    function Address(){
        const [success, setSuccess] = useState('')
        const [name, setName] = useState('')
        const [state, setState] = useState('')
        const [city, setCity] = useState('')
        const [postal, setPostal] = useState('')
        const [mobile, setMobile] = useState('')
        const [address, setAddress] = useState('')

        useEffect(() => {
            var formData = new FormData()
            formData.append('get-address', '')
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setName(data.ca_name); setState(data.ca_state); setCity(data.ca_city); setPostal(data.ca_postal); setMobile(data.ca_mobile); setAddress(data.ca_address)
                }
            })
        }, [])

        const [adding, setAdding] = useState(false)
        const [addresswarn, setAddresswarn] = useState('')
        const add_address = () => {
            setAdding(true); setAddresswarn('')
            var formData = new FormData()
            formData.append('add-address', name)
            formData.append('state', state)
            formData.append('city', city)
            formData.append('postal', postal)
            formData.append('mobile', mobile)
            formData.append('address', address)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setAdding(false)
                if(data.warning){
                    setAddresswarn(data.warning)
                } else {
                    setSuccess('Address updation succeed');
                }
            })
        }

        return(
            <Dialog open={true} fullWidth={true} maxWidth='xs' fullScreen={fullScreen} onClose={() => navi(-1)} >
                <DialogTitle>Shipping address</DialogTitle><Divider/>
                <DialogContent>
                    <Stack spacing={1.5} >
                        <TextField variant="outlined" label='Full name' type="text" value={name} onChange={(event) => setName(event.target.value)} />
                        <TextField variant="outlined" label='State' type="text" value={state} onChange={(event) => setState(event.target.value)} />
                        <TextField variant="outlined" label='City' type="text" value={city} onChange={(event) => setCity(event.target.value)} />
                        <TextField variant="outlined" label='Postal code' type="number" value={postal} onChange={(event) => setPostal(event.target.value)} />
                        <TextField variant="outlined" label='Mobile number' type="number" value={mobile} onChange={(event) => setMobile(event.target.value)} />
                        <TextField variant="outlined" label='Address' multiline rows={3} type="text" value={address} onChange={(event) => setAddress(event.target.value)} />
                        { addresswarn && <Alert severity="warning">{addresswarn}</Alert> }
                        { success && <Alert severity="success">{success}</Alert> }
                    </Stack>
                </DialogContent><Divider/>
                <DialogActions>
                    <Button color="inherit" onClick={() => navi(-1)} >Close</Button>
                    <Button color="primary" variant="contained" disabled={adding} onClick={() => add_address()} >{adding ? <CircularProgress color="inherit" size={24} /> : 'Update'}</Button>
                </DialogActions>
            </Dialog>
        );
    }

}