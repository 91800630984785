import React, { useEffect, useState } from "react";
import { } from 'react-router-dom'

// MUI
import { Alert, Card, CardContent, Container, Grid, LinearProgress, Typography } from '@mui/material'
import { } from '@mui/icons-material'

export default function UserAddress({api}){

    const [address, setAddress] = useState([])
    const [addWarn, setAddWarn] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        var formData = new FormData()
        formData.append('address', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setAddWarn(data.warning)
            } else {
                setAddress(data)
            }
        })
    }, [api])

    return(
        <Container>
            <Grid container spacing={2} mt={4} >
                { loading ? <LinearProgress color="success" /> :
                addWarn ? 
                <Grid container justifyContent='center' >
                    <Grid item xs={12} md={6} sx={{textAlign: 'center'}} >
                        <img src={`/assets/empty.svg`} style={{width: '50%'}} alt='Empty preview' />
                        <Alert severity="warning">{addWarn}</Alert>
                    </Grid>
                </Grid> :
                <Grid item xs={12} md={12}>
                    <Card elevation={0}>
                        <CardContent>
                            <Typography variant="h4">{address.ca_name}</Typography>
                            <Typography variant='body1' mt={1} color='gray' >Address: {address.ca_address}</Typography>
                            <Typography variant='body1' color='gray' >City: {address.ca_city}</Typography>
                            <Typography variant="body1" color='gray' >Postal: {address.ca_postal}</Typography>
                            <Typography variant="body1" color='gray' >State: {address.ca_state}</Typography>
                            <Typography variant="body1" color='gray' >Mobile: {address.ca_mobile}</Typography>
                        </CardContent>
                    </Card>
                </Grid>}
            </Grid>
        </Container>
    );
}