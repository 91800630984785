import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'

import AboutProductAdmin from './about'

// MUI
import { Alert, Box, Button, Checkbox, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, ImageList, ImageListItem, Input, InputAdornment, InputLabel, LinearProgress, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Add, Book, Collections, ContentCopy, Delete, Edit, PhotoCamera, Search } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';

export default function AdminProduct({product, api}){
    const fullScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const [warning, setWarning] = useState('')

    const navi = useNavigate()

    const [products, setProducts] = useState([])

    const [searchQuery, setSearchQuery] = useState('')

    const searchProduct = () => {
        setWarning('')
        var formData = new FormData()
        formData.append('products', searchQuery)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setProducts(data)
            }
        })
    }

    useEffect(() => {
        searchProduct()
    }, [])
    const keyUpSearch = (event) => {
        setSearchQuery(event.target.value)
        if(event.keyCode === 13){
            searchProduct()
        }
    }

    const [loading, setLoading] = useState(true)
    const clickToClipboard = (event) => {
        navigator.clipboard.writeText(event)
    }

    return(
        <Box className="content">
            <Container>
                <FormControl fullWidth variant="standard" >
                    <InputLabel>Search product</InputLabel>
                    <Input type="text" placeholder="Search by product name, brand..." onKeyUp={(event) => keyUpSearch(event)} endAdornment={<InputAdornment position="end"><IconButton onClick={() => searchProduct()} ><Search/></IconButton></InputAdornment>} />
                </FormControl>

                <Typography align="center" mb={1} mt={1}>
                    <Button color="success" startIcon={<Add/>} onClick={() => navi(`configure/0`)} >ADD PRODUCT</Button>
                </Typography>

                { loading ? <LinearProgress color="success" /> :
                warning ? <Alert severity="warning">{warning}</Alert> :
                <TableContainer component={Paper} elevation={0} sx={{maxHeight: 500}}>
                    <Table stickyHeader >
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" >#</TableCell>
                                <TableCell align="left" >NAME</TableCell>
                                <TableCell align="left" >PRICE</TableCell>
                                <TableCell align="left" >DATE</TableCell>
                                <TableCell align="left" >STATUS</TableCell>
                                <TableCell align="left" >CATEGORY</TableCell>
                                <TableCell align="left">IMAGES</TableCell>
                                <TableCell align="left">ABOUT</TableCell>
                                <TableCell align="left" >EDIT</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { products.map((pro, i) =>
                            <TableRow key={i} >
                                <TableCell align="left" >{products.length-i}</TableCell>
                                <TableCell align="left" >
                                    {pro.p_name}
                                    <Typography variant="body2" color='gray' >{pro.productid}<IconButton size="small" onClick={() => clickToClipboard(pro.productid)} ><ContentCopy fontSize="small" /></IconButton></Typography>
                                </TableCell>
                                <TableCell align="left" >&#8377;{Number(pro.p_price).toLocaleString('en-in')}</TableCell>
                                <TableCell align="left" >{pro.p_upload}</TableCell>
                                <TableCell align="left" >{pro.p_status}</TableCell>
                                <TableCell align="left" >{pro.p_category}</TableCell>
                                <TableCell align="left"><IconButton onClick={() => navi(`images/${pro.productid}`)} ><Collections/></IconButton></TableCell>
                                <TableCell align="left"><IconButton onClick={() => navi(`about/${pro.productid}/${pro.p_category}`)} ><Book/></IconButton></TableCell>
                                <TableCell align="left" ><IconButton onClick={() => navi(`configure/${pro.productid}`)} ><Edit/></IconButton></TableCell>
                            </TableRow>) }
                        </TableBody>
                    </Table>
                </TableContainer> }
            </Container>

            <Routes>
                <Route path="configure/:productid" element={<Configure />} />
                <Route path="images/:productid" element={<Images />} />
                <Route path="about/:productid/:category" element={<AboutProductAdmin api={api} />} />
            </Routes>
        </Box>
    );

    function Configure(){
        const { productid } = useParams()

        const [name, setName] = useState('')
        const [brand, setBrand] = useState('')
        const [color, setColor] = useState('')
        const [mrp, setMrp] = useState('')
        const [price, setPrice] = useState('')
        const [gst, setGst] = useState('')
        const [category, setCategory] = useState('')
        const [status, setStatus] = useState('')
        const [emi, setEmi] = useState(1)
        const [working, setWorking] = useState(false)
        const [warning, setWarning] = useState('')

        useEffect(() => {
            var formData = new FormData()
            formData.append('product-details', productid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                if(!data.warning){
                    setName(data.p_name); setBrand(data.p_brand); setColor(data.p_color); setMrp(data.p_mrp); setPrice(data.p_price); setGst(data.p_gst); setCategory(data.p_category); setStatus(data.p_status); setEmi(data.p_emi)
                }
            })
        }, [productid])

        const upload = () => {
            setWorking(true); setWarning('')
            var formData = new FormData()
            formData.append('configure-product', productid)
            formData.append('name', name)
            formData.append('brand', brand)
            formData.append('color', color)
            formData.append('mrp', mrp)
            formData.append('price', price)
            formData.append('gst', gst)
            formData.append('category', category)
            formData.append('status', status)
            formData.append('emi', emi)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setWorking(false)
                if(data.warning){
                    setWarning(data.warning);
                } else if(data.success){
                    searchProduct(); navi(-1)
                }
            })
        }

        const [opendel, setOpendel] = useState(false)
        const [deletingproduct, setDeletingproduct] = useState(false)
        const [confirmdelete, setConfirmdelete] = useState('')
        const [delwarn, setDelwarn] = useState('')
        const delete_product = () => {
            setDelwarn(''); setDeletingproduct(true)
            var formData = new FormData()
            formData.append('delete-product', productid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setDeletingproduct(false)
                if(data.warning){
                    setDelwarn(data.warning)
                } else {
                    searchProduct(); navi(-1)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth={true} maxWidth='sm' fullScreen={fullScreen} onClose={() => navi(-1)} >
                <DialogContent>Product details</DialogContent><Divider/>
                <DialogContent>
                    <Stack spacing={3} >
                        <TextField fullWidth variant="standard" type='text' label='Product name' value={name} onChange={(event) => setName(event.target.value)} />
                        <TextField fullWidth variant="standard" type='text' label='Brand' value={brand} onChange={(event) => setBrand(event.target.value)} />
                        <TextField fullWidth variant="standard" type='text' label='Colour' value={color} onChange={(event) => setColor(event.target.value)} />
                        <TextField fullWidth variant="standard" type='number' label='MRP (Rupee)' value={mrp} onChange={(event) => setMrp(event.target.value)} />
                        <TextField fullWidth variant="standard" type='number' label='Price (Rupee)' value={price} onChange={(event) => setPrice(event.target.value)} />
                        <TextField fullWidth variant="standard" type='number' label='GST(%) (IGST+CGST)' value={gst} onChange={(event) => setGst(event.target.value)} />
                        <FormControl variant="standard" fullWidth>
                            <InputLabel>Category</InputLabel>
                            <Select value={category} onChange={(event) => setCategory(event.target.value)}>
                                <MenuItem value='Mobile' >Mobile</MenuItem>
                                <MenuItem value='Refrigerator' >Refrigerator</MenuItem>
                                <MenuItem value='Whashing Machine' >Washing Machine</MenuItem>
                                <MenuItem value='Speaker' >Speaker</MenuItem>
                                <MenuItem value='Inverter' >Inverter</MenuItem>
                                <MenuItem value='Inverter Battery'>Inverter Battery</MenuItem>
                                <MenuItem value='TV'>Tv</MenuItem>
                                <MenuItem value='Air Conditioner'>Air Conditioner</MenuItem>
                                <MenuItem value='Chimney'>Chimney</MenuItem>
                                <MenuItem value='Mixer Grinder'>Mixer Grinder</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select value={status} onChange={(event) => setStatus(event.target.value)} >
                                <MenuItem value='Available' >Available</MenuItem>
                                <MenuItem value='Not Available' >Not Available</MenuItem>
                            </Select>
                        </FormControl>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' >
                            <Typography color='gray' >EMI available</Typography>
                            <Checkbox checked={Boolean(parseInt(emi))} onChange={() => setEmi(emi === '1' ? '0' : '1')} />
                        </Stack>
                        { warning && <Alert severity="warning">{warning}</Alert> }
                    </Stack>
                </DialogContent><Divider/>
                <DialogActions>
                    <Button color="error" variant="text" onClick={() => setOpendel(true)} >Delete</Button>
                    <Button color="inherit" variant="text" onClick={() => navi(-1)} >Close</Button>
                    <Button color="success" variant="text" onClick={() => upload()} disabled={working} >{working ? <CircularProgress color="inherit" size={24} /> : 'Upload'}</Button>
                </DialogActions>

                <Dialog open={opendel} fullWidth maxWidth='xs' onClose={() => setOpendel(false)} >
                    <DialogContent>
                        <Box pt={2} pb={2} >
                            <Typography fontSize={23} color='red' fontWeight='bold' >Delete this product?</Typography>
                            <Typography fontSize={14} color='orange' fontWeight='bold' >Remember, you can't undo this process.</Typography>
                            <Typography mt={2.5} mb={1} fontSize={14} color='gray' >To delete this product type '<i>delete</i>' below.</Typography>
                            <TextField fullWidth variant="outlined" type="text" placeholder="delete" value={confirmdelete} onChange={(event) => setConfirmdelete(event.target.value)} />

                            <Box textAlign='center' mt={2} mb={2} >
                                <Button variant="contained" color='error' disabled={ deletingproduct ? true : confirmdelete === 'delete' ? false : true } onClick={() => delete_product()} >{deletingproduct ? <CircularProgress color="inherit" size={24} /> : 'Delete'}</Button>
                            </Box>
                            {delwarn && <Alert severity="warning">{delwarn}</Alert>}
                        </Box>
                    </DialogContent>
                </Dialog>
            </Dialog>
        );
    }

    function Images(){

        const { productid } = useParams()

        const [images, setImages] = useState([])
        const [warning, setWarning] = useState('')
        const [loading, setLoading] = useState(true)

        useEffect(() => {
            productImages(productid)
        }, [productid])

        const productImages = (productid) => {
            setWarning('')
            var formData = new FormData()
            formData.append('product-images', productid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setLoading(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    setImages(data)
                }
            })
        }

        const [uploading, setUploading] = useState(false)
        const uploadImages = (event) => {
            setUploading(true); setWarning('')
            var images = event.target.files
            var formData = new FormData()
            formData.append('upload-product-images', productid)
            for(var i=0; i<images.length; i++){
                var sendImages = images[i]
                formData.append('image[]', sendImages, sendImages.name)
            }
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setUploading(false)
                if(data.warning){
                    setWarning(data.warning)
                } else {
                    productImages(productid)
                }
            })
        }

        const [working, setWorking] = useState(false)
        const deleteImg = () => {
            setWarning(''); setWorking(true)
            var formData = new FormData()
            formData.append('delete-product-img', productid)
            fetch(api, {
                method: 'post',
                body: formData
            }).then(res => res.json())
            .then(data => {
                setWorking(false)
                if(data.warning){
                    setWarning(data.warning)
                } else if(data.success){
                    productImages(productid)
                }
            })
        }

        return(
            <Dialog open={true} fullWidth={true} maxWidth='xs' onClose={() => navi(-1)}>
                <DialogTitle>Product description</DialogTitle><Divider/>
                <DialogContent>
                    <Stack direction='row' spacing={2} mb={2} justifyContent='center' divider={<Divider orientation="vertical" flexItem />} >
                        <Button size="large" color="success" disabled={uploading ? true : working ? true : images.length === 6 && true } variant="text" startIcon={<PhotoCamera/>} component='label' >
                            <input type='file' multiple accept=".webp" hidden onChange={(event) => uploadImages(event)} />
                            <Typography noWrap >Add Images</Typography>
                        </Button>
                        { !warning && 
                        <Button size="large" color="error" variant="text" startIcon={working ? <CircularProgress color='inherit' size={24} /> : <Delete/>} component='label' disabled={working || uploading } onClick={() => deleteImg()} >
                            <Typography noWrap >Delete</Typography>
                        </Button>}
                    </Stack>
                    <Typography variant="body2" className="text-mute" >*Please only select 6 images for better preview.</Typography>
                    <Typography variant="body2" className="text-mute" >*Select images serial wise.</Typography>
                    <Typography align="center" mb={3}>{uploading && 'Uploading... Please wait.' }</Typography>
                    {loading ? <LinearProgress color="success" /> :
                    warning ? <Alert severity="warning">{warning}</Alert> :
                    <ImageList cols={3} >
                        { images.map((img, i) =>
                        <ImageListItem key={i} >
                            <img alt="product" src={`${product+img.pimg_name}`} />
                        </ImageListItem>) }
                    </ImageList> }
                </DialogContent><Divider/>
                <DialogActions>
                    <Button color="inherit" variant="text" onClick={() => navi(-1)} >CLOSE</Button>
                </DialogActions>
            </Dialog>
        );
    }

}