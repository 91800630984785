import React, { useEffect, useState } from "react";
import { } from 'react-router-dom'

import Recommendation from "../../pages/recommendation";

// MUI
import { Alert, Box, Card, CardContent, CardMedia, Chip, Container, Grid, LinearProgress, Typography } from '@mui/material'
import { } from '@mui/icons-material'

export default function UserOrders({product, api, publicApi}){

    const [orders, setOrders] = useState([])
    const [orderWarn, setOrderWarn] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0)
        const get_orders = localStorage.getItem('customer-orders')
        get_orders !== null && setOrders(JSON.parse(get_orders)); setLoading(false)
    }, [])

    useEffect(() => {
        var formData = new FormData()
        formData.append('orders', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(data.warning){
                setOrderWarn(data.warning)
            } else {
                localStorage.setItem('customer-orders', JSON.stringify(data))
                const get_orders = localStorage.getItem('customer-orders')
                get_orders !== null && setOrders(JSON.parse(get_orders)); setLoading(false)
            }
        })
    }, [api])

    return(
        <Container>
            <Grid container>
                <Grid item xs={12} md={8} mx='auto' >
                    <Grid container mt={4} justifyContent='center' >
                        { loading ? <LinearProgress color="primary" /> :
                        orderWarn ? 
                        <Grid item xs={12} md={6} sx={{textAlign: 'center'}} >
                            <img src={`/assets/empty.svg`} style={{width: '50%'}} alt='Empty preview' />
                            <Alert severity="warning">{orderWarn}</Alert>
                        </Grid> :
                        <Grid item xs={12}>
                            { orders.map((order, i) =>
                            <Card key={i} style={{marginBottom: '10px'}}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 120, height: 120 }}
                                        image={product+order.pimg}
                                        alt="Orders"
                                    />
                                    <CardContent sx={{width: '100%'}}>
                                        <Typography variant="h6" noWrap={false}>{order.o_name}</Typography>
                                        <Typography color="text.secondary" variant="body2" >Date - {order.o_pmt_date}</Typography>
                                        <Typography color='text.secondary' variant="body2" mb={1} >Price - &#8377;{Number(order.o_price).toLocaleString('en-in')}</Typography>
                                        {order.o_status === 'Dilivered' ? <Chip label='Dilivered' color="success" size="small" sx={{borderRadius: 0.7}} /> : <Chip label='Delivery is on the way...' color="warning" size="small" sx={{borderRadius: 0.7}} /> }
                                    </CardContent>
                                </Box>
                            </Card>) }
                        </Grid> }
                    </Grid>
                </Grid>
            </Grid>
            <Typography variant="h5" mb={2} mt={5} >Products you may like</Typography>
            <Recommendation product={product} api={publicApi} category='' />
        </Container>
    );
}