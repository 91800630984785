import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'

// MUI
import { Box, Button, CircularProgress, Typography } from '@mui/material'

export default function UserVerifyPayment({api}){
    const navi = useNavigate()
    const { txnid } = useParams()

    const [working, setWorking] = useState(true)
    const [activate, setActivate] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('verify-phonepe-txn', txnid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setWorking(false)
            setActivate(data)
        })
    }, [txnid])

    return(
        <Box sx={{height: '90vh'}} display='flex' justifyContent='center' alignItems='center' >
            { working === true &&
            <Box textAlign='center'>
                <Box>
                    <CircularProgress color="error" />
                </Box>
                <Box>
                    <Typography variant="h6" color='orange' fontWeight='bold' >Processing your payment...</Typography>
                    <Typography variant="caption" >Do not close or back this page</Typography>
                </Box>
            </Box> }

            { activate.status === true && 
            <Box textAlign='center'>
                <img src="/assets/success.gif" alt="Payment success"  />

                <Box textAlign='center' sx={{marginTop: {xs: 0, md: -18}}} >
                    <Typography color='green' fontWeight='bold' fontSize={22} >{activate.msg}</Typography>

                    <Button variant="contained" size="large" onClick={() => navi('/')} sx={{mt: 4}} >GO TO HOME</Button>
                </Box>
            </Box> }
            { activate.status === false && 
            <Box textAlign='center'>
                <img src="/assets/failed.gif" alt="Payment failed"  />

                <Box textAlign='center' sx={{marginTop: {xs: 0, md: -18}}} >
                    <Typography color='red' fontWeight='bold' fontSize={22} >{activate.msg}</Typography>
                    {activate.status === false && <Typography variant="caption" color='gray'>If your amount has been debited, you can contact us.</Typography> }

                    <Button variant="contained" size="large" onClick={() => navi('/')} sx={{mt: 4}} >GO TO HOME</Button>
                </Box>
            </Box> }
        </Box>
    );
}