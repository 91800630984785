import React, { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom'

import Recommendation from "./recommendation";

// MUI
import { Box, Button, Card, CardContent, Container, Divider, Grid, Paper, Stack, Typography } from '@mui/material'
import { Bolt } from "@mui/icons-material";

export default function Description({api, product}){
    const { productid } = useParams()

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    const [detail, setDetail] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('product-details', productid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setDetail(data)
            }
        })
    }, [productid, api])

    const [pimg, setPimg] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('product-images', productid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setPimg(data)
                setViewImg(data[0].pimg_name)
            }
        })
    }, [productid, api])

    const [viewImg, setViewImg] = useState('')

    const [description, setDescription] = useState([])
    useEffect(() => {
        var formData = new FormData()
        formData.append('product-description', productid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            if(!data.warning){
                setDescription(data)
            }
        })
    }, [productid, api])
    
    return(
        <Container>
            <Grid container mt={1} spacing={3} mb={4}>
                <Grid item xs={12} md={5}>
                    <Card variant="outlined" >
                        <CardContent>
                            <img src={product+viewImg} alt='e-world product' />
                        </CardContent><Divider/>
                        <Box sx={{overflow: 'scroll', width: '100%', textAlign: 'center'}} pt={1} pb={1} >
                            {pimg.map((data, i) =>
                            <img src={product+data.pimg_name} alt='product description' onClick={() => setViewImg(data.pimg_name)} style={{width: '70px', height: '70px'}} key={i} />)}
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Card variant="outlined" >
                        <CardContent>
                            <Typography variant="h5">{detail.p_name}</Typography>
                            <Typography variant="body1" mt={2} color='gray' >Brand: {detail.p_brand}</Typography>
                            <Typography variant="body1" mt={1} color='gray' >Color: {detail.p_color}</Typography>
                            <Stack direction='row' spacing={2} mt={4}>
                                <Typography variant="h4" sx={{fontWeight: 'bold'}}>&#8377;{Number(detail.p_price).toLocaleString('en-in')}</Typography>
                                <Typography variant='h6' sx={{textDecoration: 'line-through'}} className='text-mute'>&#8377;{Number(detail.p_mrp).toLocaleString('en-in')}</Typography>
                                <Typography variant="h6" color='green' >{Number((Number(detail.p_mrp) - (Number(detail.p_price))) *100/Number(detail.p_mrp)).toFixed(0)}% off</Typography>
                            </Stack>

                            {detail.p_emi == '1' ? <>
                                <Typography fontSize={20} fontWeight='bold' color='red' mt={2} >0% EMI Available</Typography>
                                <Typography fontSize={14} color='gray' >You can buy this product by converting the actual price into monthly EMIs.</Typography>
                                </>
                            : ''}
                            <Stack direction={{xs:'column', md: 'row'}} spacing={1} mt={3} >
                                <Link to={`/checkout/${productid}`}><Button color='warning' variant="contained" size="large" startIcon={<Bolt />} sx={{padding: '0.8em', boxShadow: 'none'}} >BUY NOW</Button></Link>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Paper elevation={0} >
                <Typography variant="h6" color='gray' align="center" p={2} >DESCRIPTION</Typography><Divider/>
                <Grid mb={3} container direction='row' padding={2} >
                    { description.map((des, i) =>
                    <Grid item xs={6} md={3} key={i} padding={2} >
                        <Typography variant="h6" >{des.pd_name}</Typography>
                        <Typography variant="body1" color='gray' >{des.pd_value}</Typography>
                    </Grid>) }
                </Grid>
            </Paper>
            
            { detail.p_category === undefined ? '' : <>
            <Typography fontSize={16} >People also like</Typography>
            <Recommendation api={api} product={product} category={detail.p_category} /> </>}
        </Container>
    );
}