import React, { useEffect, useState } from "react";
import { } from 'react-router-dom'

// MUI
import { Alert, Avatar, Container, Grid, LinearProgress, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { BubbleChart, PlusOne } from '@mui/icons-material'

export default function UserChain({imgpath, api}){

    const [refer, setRefer] = useState([])
    const [referWarn, setReferWarn] = useState('')
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setReferWarn('')
        var formData = new FormData()
        formData.append('referred', '')
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setReferWarn(data.warning)
            } else {
                setRefer(data)
            }
        })
    }, [api])

    return(
        <Container>
            { loading ? <LinearProgress color="success" /> :
            referWarn ?
            <Grid container justifyContent='center' mt={4} >
                <Grid item xs={12} md={6} sx={{textAlign: 'center'}} >
                    <img src={`/assets/empty.svg`} style={{width: '50%'}} alt='Empty preview' />
                    <Alert severity="warning">{referWarn}</Alert>
                </Grid>
            </Grid> :
            <List dense sx={{ width: '100%', marginTop: '25px' }}>
                {refer.map((refer, i) =>
                <ListItem key={i} sx={{bgcolor: 'background.paper', marginBottom: '10px', borderRadius: '3px'}}>
                    <ListItemAvatar>
                        <Avatar alt="Referral users" src={imgpath+refer.c_img} />
                    </ListItemAvatar>
                    <ListItemText primary={`${refer.cf_name} ${refer.cl_name}`} secondary={
                        <Typography
                            variant="body2"
                            color="text.primary" >
                                Referred on: {refer.c_arrive}
                        </Typography> 
                    }/>
                    <ListItemIcon ><BubbleChart color="success" sx={{margin: 'auto'}} /> <PlusOne color="inherit"  /></ListItemIcon>
                </ListItem> )}
            </List>}
        </Container>
    );
}