import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom'

// MUI
import { Alert, Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import { } from '@mui/icons-material'

export default function OrderCustomer({api, product}){
    const navi = useNavigate()
    const { customerid } = useParams()

    const [orders, setOrders] = useState([1, 1, 1, 1, 1, 1])
    const [warning, setWarning] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        var formData = new FormData()
        formData.append('orders', customerid)
        fetch(api, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.warning){
                setWarning(data.warning)
            } else {
                setOrders(data)
            }
        })
    }, [customerid, api])

    return(
        <Dialog open={true} fullWidth={true} onClose={() => navi(-1)}>
            <DialogTitle>Orders</DialogTitle><Divider/>
            <DialogContent>
                <List sx={{padding: 0}} >
                    {loading ? <LinearProgress color="success" /> :
                    warning ? <Alert severity="warning">{warning}</Alert> :
                    orders.map((order, i) =>
                    <ListItem sx={{background: '#f6f6f6', marginTop: '10px'}} key={i} >
                        <ListItemAvatar>
                            <Avatar src={`${product}/${order.pimg}`} sx={{borderRadius: 0}} />
                        </ListItemAvatar>
                        <ListItemText>
                            <Typography variant="h6" >{order.o_name}</Typography>
                            <Typography variant="body1" noWrap >&#8377;{Number(order.o_price).toLocaleString('en-in')}</Typography>
                            <Typography variant="caption" >Delivered: {order.o_date}</Typography>
                        </ListItemText>
                    </ListItem>) }
                </List>
            </DialogContent><Divider/>
            <DialogActions>
                <Button color="inherit" variant="text" onClick={() => navi(-1)} >CLOSE</Button>
            </DialogActions>
        </Dialog>
    );
}