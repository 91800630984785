import React from "react";
import { useNavigate } from 'react-router-dom'

// MUI
import { AppBar, Divider, IconButton, Toolbar, Typography } from '@mui/material'
import { CloseRounded } from '@mui/icons-material'

export default function Bar({text}){
    const navi = useNavigate()

    return(
        <AppBar color="primary" position="sticky" sx={{boxShadow: 0}} >
            <Toolbar>
                <Typography variant="body1" noWrap color='inherit' sx={{flexGrow: 1}} >{text}</Typography>
                <IconButton color="inherit" edge='start' onClick={() => navi(-1)} ><CloseRounded/></IconButton>
            </Toolbar><Divider/>
        </AppBar>
    );
}